<template>
  <div class="h-100">
    <widget-template :id="id" name="Text Editor" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')">
      <div class="w-full h-full" v-html="contentSummary"></div>
    </widget-template>

    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:svg>
          <div class="w-full h-full flex items-center justify-center">
            <i class="text-white" :class="icon" style="font-size: 40px"></i>
          </div>
        </template>
        <template v-slot:title>Text Editor Configuration</template>
        <template v-slot:body>
          <group-input label="About 'Text Editor' Block" class="text-center">
            <p class="mt-4">
              <i class="fas fa-info-square"></i> A variable-width text block with block-based WYSIWYG editor
            </p>
          </group-input>
          <group-input label="Meta &amp; Presentation">
            <div class="row">
              <div class="col-4">
                <theme-selector label="Theme" v-model="widget.theme" />
              </div>
            </div>
          </group-input>
          <group-input label="Content">
            <wysiwyg-input v-model="widget.content" />
          </group-input>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetTemplate from "./WidgetTemplate.vue";
import WidgetConfigModal from "./WidgetConfigModal";

export default {
  components: {
    WidgetTemplate,
    WidgetConfigModal,
  },
  props: ["widget", "icon", "id"],
  computed: {
    contentSummary() {
      // Get the first of the Blocks
      if (this.widget.content && this.widget.content.blocks) {
        let firstBlock = this.widget.content.blocks[0];
        let text = firstBlock.data.text;
        if (text.length > 500) {
          text = text.substring(0, 500) + "...";
        }
        return text;
      }
      return "";
    },
  },
  methods: {
    config() {
      this.$refs["modal-" + this.id].show();
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.banner-content {
  color: white;
  background: black;
}
</style>