<template>
  <div style="height: 100%">
    <div class="widget header handle" :style="'background:' + widget.colour">
      <div>
        <i :class="icon"></i>
        <strong>Active Campaign</strong>
        <div v-if="widget.label" style="font-size: 80%">{{ widget.label }}</div>
      </div>
      <widget-config-btn @click="config" />
      <widget-delete-btn @click="deleteSelf" />
      <widget-duplicate-btn @click="duplicate" />
    </div>
    <span class="widget-text-content" v-if="widget.heading">
      {{
      widget.heading
      }}
    </span>
    <br />
    <span class="widget-text-content" v-if="widget.subheading">
      {{
      widget.subheading
      }}
    </span>
    <br />
    <span class="widget-text-content" v-if="widget.activeCampaignId">
      {{
      widget.activeCampaignId
      }}
    </span>
    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:svg>
          <div class="w-full h-full flex items-center justify-center">
            <i class="text-white" :class="icon" style="font-size: 40px"></i>
          </div>
        </template>
        <template v-slot:title>
          <h5>Active Campaign Editor</h5>
        </template>
        <template v-slot:subtitle>{{ widget.label }}</template>
        <template v-slot:body>
          <div class="container">
            <div class="grid grid-cols-2 gap-2">
              <label class="block text-sm flex items-center">
                <span class="w-1/3 text-gray-700 text-right mr-4">Label</span>
                <input v-model="widget.label" class="w-2/3 block w-full mt-1 text-sm form-input" />
              </label>
              <div></div>

              <label class="block text-sm flex items-center">
                <span class="w-1/3 text-gray-700 text-right mr-4">Heading</span>
                <input v-model="widget.heading" class="w-2/3 block w-full mt-1 text-sm form-input" />
              </label>

              <label class="block text-sm flex items-center">
                <span class="w-1/3 text-gray-700 text-right mr-4">Sub Heading</span>
                <input v-model="widget.subheading" class="w-2/3 block w-full mt-1 text-sm form-input" />
              </label>

              <label class="block text-sm flex items-center">
                <span class="w-1/3 text-gray-700 text-right mr-4">Active Campaign Id</span>
                <select v-model="widget.activeCampaignId" class="w-2/3 block w-full mt-1 text-sm form-select">
                  <option v-for="(campaign, index) in activeCampaignArray" :key="index" :value="campaign.id">{{ campaign.name }}</option>
                </select>
              </label>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-red" @click="deleteSelf">Delete Widget</button>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetConfigBtn from "./WidgetConfigBtn";
import WidgetConfigModal from "./WidgetConfigModal";
import WidgetDuplicateBtn from "./WidgetDuplicateBtn";
import WidgetDeleteBtn from "./WidgetDeleteBtn";
import axios from "./../../http.js";
import _ from "lodash";

export default {
  components: {
    WidgetConfigBtn,
    WidgetConfigModal,
    WidgetDuplicateBtn,
    WidgetDeleteBtn,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {
      activeCampaignArray: [],
    };
  },
  methods: {
    deleteSelf() {
      if (confirm("Do you really want to delete?")) {
        this.$refs["modal-" + this.id].hide();
        this.$emit("delete");
      }
    },
    config() {
      this.$refs["modal-" + this.id].show();
    },
    duplicate() {
      if (confirm("duplicate item?")) {
        this.$emit("duplicate");
      }
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
    getForms(option) {
      let refresh = option ?? "";
      axios.get("/api/forms" + refresh).then((response) => {
        this.$nextTick(() => {
          let that = this;
          _.forEach(response.data.data.forms, function (campaign) {
            that.activeCampaignArray.push({
              id: campaign.id,
              name: campaign.name,
            });
          });
        });
      });
    },
    refreshForms() {
      if (confirm("Do you really want to refresh the forms?")) {
        this.getForms("?refresh=true");
      }
    },
  },
  mounted() {
    axios
      .get("/api/forms")
      .then((response) => {
        this.$nextTick(() => {
          let that = this;
          _.forEach(response.data.data.forms, function (campaign) {
            that.activeCampaignArray.push({
              id: campaign.id,
              name: campaign.name,
            });
          });
        });
      })
      .finally(() => {});
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
</style>