<template>
  <div class="h-100" style="background: black;">
    <widget-template :id="id" name="Raw HTML" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')">
      <div>
        <prism-editor class="my-editor" v-model="widget.content" :highlight="highlighter" line-numbers readonly></prism-editor>
      </div>
    </widget-template>
    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:svg>
          <div class="w-full h-full flex items-center justify-center">
            <i class="text-white" :class="icon" style="font-size: 40px"></i>
          </div>
        </template>
        <template v-slot:title>
          <h5>Raw HTML Configuration</h5>
        </template>
        <template v-slot:subtitle>{{ widget.label }}</template>
        <template v-slot:body>
          <group-input label="About 'Banner' Block" class="text-center">
            <p class="mt-4">
              <i class="fas fa-info-square"></i> Renders HTML exactly as you enter it.
            </p>
            <p class="mt-4 text-danger text-strong">
              <i class="fas fa-exclamation-triangle"></i>
              <strong>CAUTION:</strong> This can be
              <strong>
                <a href="https://owasp.org/www-community/attacks/xss/" target="_blank">risky</a>
              </strong>. Only enter HTML from trusted sources.
            </p>
          </group-input>
          <group-input label="Meta &amp; Presentation">
            <div class="row">
              <div class="col">
                <text-input v-model="widget.label" label="Label" />
                <p class="text-muted mt-2">
                  <i class="far fa-lightbulb"></i> Label is used for reference only and never shown on the front end.
                </p>
              </div>
              <div class="col"></div>
            </div>
          </group-input>
          <group-input label="Editor" :nopadding="true">
            <prism-editor class="my-editor" v-model="widget.content" :highlight="highlighter" line-numbers></prism-editor>
          </group-input>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetConfigModal from "./WidgetConfigModal";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
import WidgetTemplate from "./WidgetTemplate.vue";

export default {
  components: {
    WidgetTemplate,
    WidgetConfigModal,
    PrismEditor,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {};
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js); // languages.<insert language> to return html with markup
    },
    config() {
      this.$refs["modal-" + this.id].show();
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
  mounted() {
    setInterval(() => {}, 500);
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.banner-content {
  color: white;
  background: black;
}

.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;
  color: #ccc;
  /* you must provide font-family font-size line-height. Example: */
  font-family: Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  border-radius: 0 0 5px 5px;
  min-height: 200px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>