<template>
  <div class="h-100">
    <widget-template :id="id" name="FAQs" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')">
      <div class="row px-1">
        <div class="col">
          <div class="bg-light rounded-2 p-1">
            <table class="table table-light">
              <thead>
                <tr>
                  <th>Heading</th>
                  <th>Type</th>
                  <th v-if="widget.type == 'category'">Category</th>
                  <th v-if="widget.type == 'selected'">Count</th>
                  <th v-if="widget.type == 'orphan'"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ widget.heading }}</td>
                  <td>{{widget.type}}</td>
                  <td v-if="widget.type == 'category'">{{widget.category}}</td>
                  <th v-if="widget.type == 'selected'">{{widget.faqs.length}}</th>
                  <th v-if="widget.type == 'orphan'"></th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </widget-template>
    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:svg>
          <div class="w-full h-full flex items-center justify-center">
            <i class="text-white" :class="icon" style="font-size: 40px"></i>
          </div>
        </template>
        <template v-slot:title>
          <h5>Products Configuration</h5>
        </template>
        <template v-slot:subtitle>{{ widget.label }}</template>
        <template v-slot:body>
          <group-input label="About 'Products' Block" class="text-center">
            <p class="mt-4">
              <i class="fas fa-info-square"></i> A customisable slider in which products are displayed.
            </p>
          </group-input>
          <group-input label="Meta &amp; Presentation">
            <div class="row">
              <div class="col">
                <text-input v-model="widget.label" label="Label" />
                <p class="text-muted mt-2">
                  <i class="far fa-lightbulb"></i> Label is used for reference only and never shown on the front end.
                </p>
              </div>
            </div>
          </group-input>

          <div class="row">
            <div class="col">
              <group-input label="Content">
                <text-input label="Heading" v-model="widget.heading" />
              </group-input>
            </div>
            <div class="col">
              <group-input label="Critera">
                <select-input label="FAQs Selection Mode" :options="faqsSelectionModes" v-model="widget.type" />
                <categories-select v-if="widget.type == 'category'" v-model="widget.category" label="Product category" />
                <faqs-input v-if="widget.type == 'selected'" v-model="widget.faqs" type="question" key="question" label="FAQs" />
              </group-input>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetConfigModal from "./WidgetConfigModal";
import CategoriesSelect from "./helpers/Categories";
import FaqsInput from "./helpers/FAQs";
import WidgetTemplate from "./WidgetTemplate.vue";

export default {
  components: {
    WidgetTemplate,
    WidgetConfigModal,
    CategoriesSelect,
    FaqsInput,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {
      faqsSelectionModes: [
        { value: "orphan", label: "Orphan" },
        { value: "category", label: "Category" },
        { value: "selected", label: "Selected" },
      ],
      orderingOptions: [
        { value: "price_asc", label: "Price Asc" },
        { value: "price_desc", label: "Price Desc" },
        { value: "name_asc", label: "Name Asc" },
        { value: "name_desc", label: "Name Desc" },
      ],
    };
  },
  methods: {
    config() {
      this.$refs["modal-" + this.id].show();
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
  mounted() {
    setInterval(() => {}, 500);
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.banner-content {
  color: white;
  background: black;
}
.widget-text-content {
  padding: 3px;
}
</style>