<template>
  <div class="h-100">
    <widget-template :id="id" name="Products" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')">
      <div class="row px-1">
        <div class="col">
          <div class="bg-light rounded-2 p-1">
            <table class="table table-light">
              <thead>
                <tr>
                  <th>Heading</th>
                  <th>Type</th>
                  <th v-if="widget.type == 'category'">Category</th>
                  <th>Count</th>
                  <th>Order By</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ widget.heading }}</td>
                  <td>{{widget.type}}</td>
                  <td v-if="widget.type == 'category'">{{ widget.category }}</td>
                  <td>{{ widget.numberOfProducts }}</td>
                  <td>{{ widget.orderBy }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </widget-template>

    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:svg>
          <div class="w-full h-full flex items-center justify-center">
            <i class="text-white" :class="icon" style="font-size: 40px"></i>
          </div>
        </template>
        <template v-slot:title>
          <h5>Products Configuration</h5>
        </template>
        <template v-slot:body>
          <group-input label="About 'Products' Block" class="text-center">
            <p class="mt-4">
              <i class="fas fa-info-square"></i> A customisable slider in which products are displayed.
            </p>
          </group-input>

          <div class="row">
            <div class="col">
              <group-input label="Content">
                <div class="row">
                  <div class="col-12">
                    <select-input label="Theme" :options="[{value: 'carousel', label: 'Carousel'},{value: 'grid', label: 'Grid'}]" v-model="widget.theme" />
                  </div>
                  <div class="col-12">
                    <text-input label="Heading" v-model="widget.heading" />
                  </div>
                </div>
              </group-input>
            </div>
            <div class="col">
              <group-input label="Critera">
                <select-input label="Product Selection Mode" :options="productSelectionModes" v-model="widget.type" />
                <categories-select v-if="widget.type == 'category'" v-model="widget.category" label="Product category" />
                <collections-select v-if="widget.type == 'collections'" v-model="widget.collections" type="name" key="name" label="Product collections" />
                <tags-input class="mt-2" v-if="widget.type == 'tags'" v-model="widget.tags" />
                <products-input v-if="widget.type == 'names'" v-model="widget.products" type="name" key="name" label="Products" />
                <products-input v-if="widget.type == 'skus'" v-model="widget.skus" type="sku" key="sku" label="SKUs" />

                <div class="form-group mt-2">
                  <label for>Number of Products</label>
                  <span class="bg-dark text-light p-1 d-flex align-items-center justify-content-center rounded-2 fw-bold my-2">{{ widget.numberOfProducts }}</span>
                  <input type="range" min="1" max="100" v-model="widget.numberOfProducts" class="form-range" />
                </div>
                <select-input v-model="widget.orderBy" label="Order By" :options="orderingOptions" />
                <select-input v-if="widget.orderBy == 'strategy'" v-model="widget.orderingStrategy" label="Strategy" :options="orderingStrategies" />
              </group-input>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetConfigModal from "./WidgetConfigModal";
import CategoriesSelect from "./helpers/Categories";
import CollectionsSelect from "./helpers/Collections";
import TagsInput from "./helpers/Tags";
import ProductsInput from "./helpers/Products";
import axios from "../../http.js";
import WidgetTemplate from "./WidgetTemplate.vue";

export default {
  components: {
    WidgetTemplate,
    WidgetConfigModal,
    CategoriesSelect,
    CollectionsSelect,
    TagsInput,
    ProductsInput,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {
      productSelectionModes: [
        // { value: "latest", label: "Latest" },
        // { value: "featured", label: "Featured" },
        { value: "category", label: "Category" },
        // { value: "tags", label: "Tags" },
        { value: "names", label: "Names" },
        { value: "skus", label: " SKUs" },
        // { value: "on_sell", label: "On sale" },
        // { value: "best_selling", label: "Best selling" },
        { value: "collections", label: "Collections" },
        { value: "random", label: "Random" },
      ],
      orderingOptions: [
        { value: "price_asc", label: "Price Asc" },
        { value: "price_desc", label: "Price Desc" },
        { value: "strategy", label: "Strategy" },
      ],
      orderingStrategies: [],
    };
  },
  methods: {
    config() {
      this.$refs["modal-" + this.id].show();
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
  mounted() {
    axios.get("/api/admin/ordering-strategies").then((r) => {
      this.orderingStrategies = r.data.data.map((strategy) => {
        return {
          value: strategy.id + "_" + strategy.slug,
          label: strategy.name,
        };
      });
      // Prepend the _default strategy
      this.orderingStrategies.unshift({
        value: "_default",
        label: "Use the default strategy",
      });
    });
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.banner-content {
  color: white;
  background: black;
}
.widget-text-content {
  padding: 3px;
}
</style>