<template>
  <div>
    <div class="top-header bg-dark p-3 rounded-2 text-white" :class="{'draft': draftMode}">
      <div class="row justify-content-between">
        <div class="col-auto text-center d-flex align-items-center">
          <h2>
            {{ pageData.title }}
            <span class="ms-2" style="font-size:0.6em">({{pageData.status == 'published' ? 'published' : 'unpublished'}})</span>
          </h2>
        </div>
        <div class="col-auto">
          <div class="row">
            <div class="col-auto" v-if="livePreviewActive">
              <input class="form-control" type="text" readonly :value="livePreviewUrl" />
            </div>
            <div class="col-auto" v-if="livePreviewActive">
              <button class="vave-btn btn-red" @click="endPreviewSession">Stop</button>
            </div>
            <div class="col-auto" v-if="!livePreviewActive">
              <button class="vave-btn btn-outline-blue" @click="beginPreviewSession" :disabled="livePreviewInitialising">
                <i class="far fa-eye pe-1"></i> Start Live Preview
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-light p-3 shadow rounded-2 mt-2">
      <div class="row justify-content-between">
        <div class="col-auto d-flex">
          <select v-model="version" @change="loadVersion()" class="form-select">
            <option v-for="(v, key) in allAvailableVersions" :key="'versions-'+key" :value="v.id">{{ v.counter }} {{ v.created_at | formattedDate }} {{v.id == publishedVersion ? ' - ACTIVE': ''}}</option>
          </select>
          <button class="vave-btn ms-1" @click="createNewVersion" :disabled="drafting">New&nbsp;Draft</button>
          <button class="vave-btn ms-1 btn-green" @click="save" :disabled="saving">Save</button>
          <button class="vave-btn ms-1 btn-blue" @click="publish" :disabled="publishing || !draftMode">Make&nbsp;Active</button>
        </div>
        <div class="col-auto d-flex">
          <button class="vave-btn ms-1" @click="showExportConfigModal()">Export</button>
          <button class="vave-btn ms-1" @click="importConfig()">Import</button>
          <button class="vave-btn ms-1" @click="showMetaEditor">Edit&nbsp;Meta</button>
        </div>
        <div class="col-auto d-flex">
          <button class="vave-btn me-1" @click="showWidgetPicker">
            <i class="fas fa-plus"></i> Add&nbsp;Widget
          </button>
          <button @click="togglePreventCollision" class="vave-btn">
            <div v-if="preventCollision">
              <span>
                <i class="fas fa-lock pe-1"></i> Unlock widgets
              </span>
            </div>
            <div v-else>
              <span>
                <i class="fas fa-lock-open pe-1"></i> Lock widgets
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="mt-1">
      <div class="bg-white shadow rounded-2">
        <div class="flex justify-between">
          <div class="flex space-x-2 items-center">
            <div>
              <div class="mr-2"></div>
            </div>
          </div>

          <div></div>
        </div>

        <grid-layout
          :layout.sync="layout"
          :is-draggable="draggable"
          :col-num="12"
          :row-height="200"
          :is-resizable="resizable"
          :is-mirrored="false"
          :vertical-compact="false"
          :margin="[10, 10]"
          :use-css-transforms="true"
          :responsive="false"
          :prevent-collision="preventCollision"
          class="bg-white mt-2 rounded shadow"
        >
          <grid-item v-bind:key="item.i" v-for="item in layout" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :isResizable="item.isResizable" :minW="item.minW" :maxW="item.maxW" :minH="1" :maxH="1" :ref="'widget-' + item.i">
            <component :id="item.i" :is="item.contains" :widget="item.widgetConfig" @loadedTemplate="updateConfig(item, $event)" :icon="item.icon" @delete="deletewidget(item)" @duplicate="duplicate(item)" @toggleDraggable="draggable=$event"></component>
          </grid-item>
        </grid-layout>
      </div>
    </div>
    <portal-target name="widget-modals" multiple></portal-target>

    <widget-config-modal ref="metaModal">
      <template v-slot:title>Edit Meta</template>
      <template v-slot:subtitle>Edit meta tags and title</template>
      <template v-slot:body>
        <div class="row">
          <div class="col-12">
            <label for="meta_title">Meta Title</label>
            <input type="text" name="meta_title" v-model="meta_title" class="form-control" />
          </div>
          <div class="col-12 mt-4">
            <label for="meta_description">Meta Description</label>
            <textarea rows="4" name="meta_description" v-model="meta_description" class="form-control" />
          </div>
          <div class="col-auto pt-4">
            <input type="checkbox" class="form-check-input" id="checkbox" @change="updateEvents()" v-model="triggerRegistrationEvent" />
            <label for="checkbox">&nbsp;Trigger registration events</label>
          </div>
          <div class="col-12 mt-4">
            <button class="vave-btn btn-green" @click="saveMeta()">Save</button>
          </div>
        </div>
      </template>
      <template v-slot:footer></template>
    </widget-config-modal>

    <widget-config-modal ref="widgetPicker">
      <template v-slot:title>Add widget</template>
      <template v-slot:subtitle>Add a widget to the grid</template>
      <template v-slot:body>
        <div v-for="widget in library" :key="widget.i" class="widget-item d-flex justify-content-between align-items-center" :class="{'deprecated': isWidgetDeprecated(widget)}" @click="addWidget(widget, $event)">
          <div class="p-3 d-flex align-items-center">
            <div style="width: 240px" class="title d-flex align-items-center">
              <i class="d-flex justify-content-center pe-4" :class="widget.icon"></i>
              <div style="font-size:1.3em;">{{ widget.contains }}</div>
            </div>
            <div>
              <div class="description">{{ widget.description }}</div>
              <span class="deprecation-note" v-if="isWidgetDeprecated(widget)">(deprecated: use custom-banner instead)</span>
            </div>
          </div>
          <div>
            <button class="vave-btn btn-blue">
              <i class="fas fa-plus" style="pointer-events: none"></i>
            </button>
          </div>
        </div>
      </template>
      <template v-slot:footer></template>
    </widget-config-modal>

    <widget-config-modal ref="exportConfigModal">
      <template v-slot:title>Export configuration</template>
      <template v-slot:subtitle>Export configuration</template>
      <template v-slot:body>
        <div class="row">
          <div class="col-12">
            <checkbox-input label="Export content" v-model="exportIncludeLayout" />
          </div>
          <div class="col-12">
            <checkbox-input label="Export meta data" v-model="exportIncludeMeta" />
          </div>
          <div class="mt-3 col-12">
            <button class="btn-primary btn" @click="exportConfig()" :disabled="!exportIncludeMeta && !exportIncludeLayout">Export</button>
          </div>
        </div>
      </template>
      <template v-slot:footer></template>
    </widget-config-modal>
  </div>
</template>

<script>
// No unuse vars
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { GridLayout, GridItem } from "vue-grid-layout";
import _ from "lodash";
import axios from "./../http.js";
import EventBus from "./../bus.js";
const uuidv4 = require("uuid/v4");
import WidgetConfigModal from "./widgets/WidgetConfigModal";
import RawHtml from "./widgets/RawHtml";
import Banner from "./widgets/Banner";
import Products from "./widgets/Products";
import Hero from "./widgets/Hero";
import CustomBanner from "./widgets/CustomBanner";
import NewHero from "./widgets/NewHero";
import NewBanner from "./widgets/NewBanner";
import Brands from "./widgets/Brands";
import Categories from "./widgets/Categories";
import Blog from "./widgets/Blog";
import Prefab from "./widgets/Prefab";
import TextEditor from "./widgets/TextEditor";
import CarbonCalculator from "./widgets/CarbonCalculator";
import ActiveCampaign from "./widgets/ActiveCampaign";
import textWithImage from "./widgets/textWithImage";
import imageBlock from "./widgets/image";
import spacer from "./widgets/spacer";
import Faqs from "./widgets/Faq";
import availableWidgets from "../availableWidgets.js";
import moment from "moment";
import hash from "object-hash";

import MqttClient from "./../mqtt.js";

export default {
  components: {
    GridLayout,
    GridItem,
    WidgetConfigModal,
    RawHtml,
    Banner,
    NewBanner,
    Hero,
    NewHero,
    Brands,
    Categories,
    ActiveCampaign,
    TextEditor,
    CarbonCalculator,
    Products,
    Blog,
    Prefab,
    textWithImage,
    CustomBanner,
    imageBlock,
    spacer,
    Faqs,
  },
  data() {
    return {
      topic: "",
      livePreviewActive: false,
      sharePreviewActive: false,
      livePreviewUrl: "",
      livePreviewInitialising: false,
      status: "",
      triggerRegistrationEvent: false,
      saving: false,
      publishing: false,
      drafting: false,
      version: 1,
      publishedVersion: 1,
      layout: [],
      draggable: true,
      resizable: true,
      index: 0,
      allAvailableVersions: {},
      pageData: {},
      currentLowestObject: -1,
      preventCollision: true,
      layoutMd5: "",
      meta_description: null,
      meta_title: null,
      exportIncludeLayout: true,
      exportIncludeMeta: true,
    };
  },
  mounted() {
    this.loadPage();
    this.loadAllVersions();
    window.addEventListener("keydown", this.saveWithKeyboard);
    this.checkActiveLivePreview();

    EventBus.$on("hashchanged", () => {
      this.layoutMd5 = hash(this.layout);
    });
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.saveWithKeyboard);
  },
  computed: {
    library() {
      // move the deprecated widgets at the bottom
      return _.sortBy(availableWidgets, (widget) => {
        return this.isWidgetDeprecated(widget) ? 1 : 0;
      });
    },
    draftMode() {
      if (this.pageData && !this.pageData.published_version) {
        return true;
      }
      if (this.pageData && this.pageData.published_version) {
        return this.version !== this.pageData.published_version.id;
      }
      return false;
    },
  },
  watch: {
    layout: {
      deep: true,
      handler(layout) {
        // Calculate the current lowest object, so we can insert the next one under it
        var epsilons = [-1];
        _.forEach(layout, (widget) => {
          epsilons.push(widget.y);
        });
        this.currentLowestObject = Math.max(...epsilons);
        if (this.livePreviewActive) {
          let newLayoutHash = hash(layout);
          if (this.layoutMd5 !== newLayoutHash) {
            this.layoutMd5 = newLayoutHash;
          }
        }
      },
    },
    layoutMd5(oldvalue, newvalue) {
      if (oldvalue === newvalue) return;
      if (this.livePreviewActive) {
        MqttClient.publish(this.topic + "/update", JSON.stringify(this.layout));
      }
    },
    $route() {
      this.loadPage();
      this.loadAllVersions();
    },
  },
  filters: {
    formattedDate(value) {
      return moment(String(value)).format("DD/MM/YYYY hh:mm");
    },
  },
  methods: {
    isWidgetDeprecated(widget) {
      return ["banner", "new-banner", "hero", "new-hero"].includes(
        widget.contains
      );
    },
    updateConfig(item, templateConfig) {
      // keep the current label and color
      templateConfig.label = item.widgetConfig.label;
      templateConfig.color = item.widgetConfig.color;
      item.widgetConfig = templateConfig;
    },
    checkActiveLivePreview() {
      if (this.$store.state.livePreviewSessionId) {
        this.beginPreviewSession(false);
      }
    },
    saveWithKeyboard(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault();
        this.save();
      }
    },
    togglePreventCollision() {
      this.preventCollision = !this.preventCollision;
    },
    beginPreviewSession(openSessionWindow = false) {
      if (this.livePreviewInitialising === true) return;
      this.livePreviewInitialising = true;

      let sessionID = this.$store.state.livePreviewSessionId
        ? this.$store.state.livePreviewSessionId
        : uuidv4();

      this.$store.commit("setLivePreviewSession", sessionID);

      this.topic = "rtp-" + sessionID;

      MqttClient.subscribe(this.topic + "/hello");
      EventBus.$on(this.topic + "/hello", () => {
        MqttClient.publish(this.topic + "/update", JSON.stringify(this.layout));
      });

      this.livePreviewActive = true;
      this.livePreviewUrl =
        process.env.VUE_APP_PWAURL + "/preview?s=" + sessionID;
      if (openSessionWindow) {
        window.open(this.livePreviewUrl).focus();
      }
      this.livePreviewInitialising = false;
    },
    endPreviewSession() {
      if (confirm("Do you want to end the live preview?")) {
        this.livePreviewUrl = null;
        this.livePreviewActive = false;
        this.livePreviewInitialising = false;
        this.$store.commit("setLivePreviewSession", null);
      }
    },
    sharePreviewLink() {
      this.sharePreviewActive = !this.sharePreviewActive;
    },
    showWidgetPicker() {
      this.$refs.widgetPicker.show();
    },
    showMetaEditor() {
      this.$refs.metaModal.show();
    },
    addWidget(widget) {
      if (
        this.isWidgetDeprecated(widget) &&
        !confirm(
          "This widget is deprecated and will be removed in the future. Are you sure you want to add it?"
        )
      ) {
        return;
      }
      var newWidget = _.cloneDeep(widget);
      newWidget.i = uuidv4();
      newWidget.y = this.currentLowestObject + 1;
      this.layout.push(newWidget);
      this.$refs.widgetPicker.hide();
      this.$nextTick(() => {
        // Scroll to the element and make it glow
        setTimeout(() => {
          var w = this.$refs["widget-" + newWidget.i][0];
          w.$el.scrollIntoView({ behavior: "smooth" });
          w.$el.classList.add("slowglow");
          w.$el.classList.add("glow");
          setTimeout(() => {
            w.$el.classList.remove("glow");
          }, 1150);
        }, 350);
      });
    },
    duplicate(item) {
      var newWidget = _.cloneDeep(item);
      newWidget.i = uuidv4();
      this.layout.push(newWidget);
    },
    loadPage() {
      axios
        .get("/api/admin/pages/" + this.$route.params.id)
        .then((response) => {
          this.status = response.data.data.status;
          this.triggerRegistrationEvent =
            response.data.data.trigger_registration_event;
          this.pageData = response.data.data;
          if (response.data.data.latest_version) {
            this.version = response.data.data.latest_version;
            this.publishedVersion = response.data.data.latest_version;
            this.loadVersion();
          } else {
            this.loadAllVersions(true);
          }
        });
    },
    loadAllVersions(createIfEmpty) {
      axios
        .get("/api/admin/pages/" + this.$route.params.id + "/versions")
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.allAvailableVersions = response.data.data;
            // Get the latest allAvailableVersions where published_at is not null
            var publishedVersions = _.filter(
              this.allAvailableVersions,
              (v) => v.published_at !== null
            );
            if (publishedVersions.length > 0) {
              this.version = _.maxBy(publishedVersions, "id").id;
              this.layout = _.maxBy(publishedVersions, "id").content;
            } else {
              // take the last draft
              this.version = _.maxBy(this.allAvailableVersions, "id").id;
              this.layout = _.maxBy(this.allAvailableVersions, "id").content;
            }
          } else {
            if (createIfEmpty) {
              this.createNewVersion();
            }
          }
        });
    },
    saveMeta() {
      axios
        .put("/api/admin/pages/" + this.$route.params.id, {
          trigger_registration_event: this.triggerRegistrationEvent,
        })
        .then(() => {
          axios
            .put(
              "/api/admin/pages/" +
                this.$route.params.id +
                "/versions/" +
                this.version,
              {
                meta_title: this.meta_title,
                meta_description: this.meta_description,
              }
            )
            .then((r) => {
              if (r.status == 200) {
                this.$toast.success("Meta saved", {
                  position: "top-right",
                });
                this.$refs.metaModal.hide();
              }
            });
        });
    },
    loadVersion() {
      axios
        .get(
          "/api/admin/pages/" +
            this.$route.params.id +
            "/versions/" +
            this.version
        )
        .then((response) => {
          let data = response.data.data;
          this.layout = [];
          this.$nextTick(() => {
            this.meta_title = data.meta_title;
            this.meta_description = data.meta_description;
            if (data.content && data.content.length !== 0) {
              this.layout = data.content;
            }
          });
        });
    },
    deletewidget(item) {
      this.layout.forEach((subject) => {
        if (subject.i == item.i) {
          var index = this.layout.indexOf(item);
          if (index !== -1) {
            this.layout.splice(index, 1);
          }
        }
      });
      this.$toast.success("Widget deleted", {
        timeout: 1800,
        hideProgressBar: true,
      });
    },
    createNewVersion() {
      this.drafting = true;

      axios
        .post("/api/admin/pages/" + this.$route.params.id + "/versions", {
          content: this.layout,
        })
        .then((response) => {
          axios
            .get("/api/admin/pages/" + this.$route.params.id + "/versions")
            .then((r) => {
              this.allAvailableVersions = r.data.data;
              this.version = response.data.data.id;
              this.$toast.success("New page version saved", {
                hideProgressBar: true,
              });
            });

          // this.loadAllVersions();
        })
        .finally(() => {
          setTimeout(() => {
            this.drafting = false;
          }, 500);
        });
    },
    save() {
      this.saving = true;
      axios
        .put(
          "/api/admin/pages/" +
            this.$route.params.id +
            "/versions/" +
            this.version,
          {
            content: this.layout,
          }
        )
        .then(
          () => {
            this.$toast.success("Page saved", {
              hideProgressBar: true,
            });
          },
          () => {
            this.$toast.error("Error saving page", {
              hideProgressBar: true,
              timeout: 10000,
            });
          }
        )
        .finally(() => {
          setTimeout(() => {
            this.saving = false;
          }, 500);
        });
    },
    publish() {
      const check = confirm(
        "Are you sure you want to save and make this Version the active for this Page?"
      );
      if (!check) {
        return;
      }
      this.publishing = true;
      this.status = "published";
      axios
        .put(
          "/api/admin/pages/" +
            this.$route.params.id +
            "/versions/" +
            this.version,
          {
            published_at: new Date(),
            content: this.layout,
          }
        )
        .then(
          () => {
            this.$toast.success("Version set as Active", {
              hideProgressBar: true,
            });
            this.loadPage();
          },
          () => {
            this.$toast.error("Error setting Version as active", {
              hideProgressBar: true,
              timeout: 10000,
            });
          }
        )
        .finally(() => {
          setTimeout(() => {
            this.publishing = false;
          }, 500);
        });
    },
    showExportConfigModal() {
      this.$refs.exportConfigModal.show();
    },
    exportConfig() {
      var data = {};
      if (this.exportIncludeLayout) {
        data.layout = this.layout;
      }
      if (this.exportIncludeMeta) {
        data.meta_description = this.meta_description;
        data.meta_title = this.meta_title;
      }
      var blob = new Blob([JSON.stringify(data)], {
        type: "application/json",
      });
      var url = URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "config.json";
      a.click();
      // Hide the modal and reset the checkbox
      this.$refs.exportConfigModal.hide();
      this.exportIncludeMeta = true;
      this.exportIncludeLayout = true;
    },
    importConfig() {
      var input = document.createElement("input");
      input.type = "file";
      input.onchange = (e) => {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          var data = JSON.parse(e.target.result);
          if (data.layout) {
            this.layout = data.layout;
            this.$toast.success("Page imported");
          }
          if (data.meta_description || data.meta_title) {
            this.meta_description = data.meta_description
              ? data.meta_description
              : this.meta_description;
            this.meta_title = data.meta_title
              ? data.meta_title
              : this.meta_title;
            this.saveMeta();
          }
        };
        reader.readAsText(file);
      };
      input.click();
    },
  },
};
</script>

<style lang="scss">
.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: none;
  border-radius: 3px;
  overflow: hidden;

  .header {
    background: rgba(black, 0.2);
    padding: 10px;
    margin-bottom: 10px;
  }
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-grid-item {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
}

.widget-text-content {
  color: white;
  background: rgba(black, 0.8);
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 4px;
  display: inline-block;

  h1 {
    font-size: 1.4em;
  }
  h2 {
    font-size: 0.9em;
  }
}
.fakebuton {
  background: #feb103;
  color: black;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
}
.notShowing {
  max-width: 0px;
  overflow: hidden;
  white-space: nowrap;
  opacity: 0;
  transition: all 250ms ease-in-out;

  &.showing {
    max-width: 300px;
    overflow: auto;
    opacity: 1;
  }
}

.flatEdge {
  border-radius: 0;
}
.transition {
  transition: all 250ms ease-in-out;
}
.success {
  color: green;
}

.slowglow {
  transition: all 500ms ease-in-out !important;
}
.glow {
  box-shadow: 0px 0px 10px 10px #fff, 0px 0px 1000px 1000px rgba(0, 0, 0, 0.8);
}
.widget-item {
  cursor: pointer;
  border-bottom: 1px solid #fafafa;
  padding-right: 10px;
  &:hover {
    background: #fafafa;
  }

  .title {
    text-transform: capitalize;
  }
  &.deprecated {
    background: #eeeeee;
    .description {
      opacity: 0.5;
      text-decoration: line-through;
    }
    .title {
      opacity: 0.5;
      text-decoration: line-through;
    }
    button {
      opacity: 0.5;
    }
  }
}
.top-header {
  background: #2980b9 !important;
  &.draft {
    background: rgb(226, 192, 70) !important;
  }
}
</style>