<template>
  <div class="h-100">
    <widget-template :id="id" name="Prefab" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')">
      <span class="widget-text-content" v-if="widget.name">
        {{
        widget.name
        }}
      </span>
    </widget-template>

    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:title>Prefab</template>
        <template v-slot:body>
          <group-input label="About 'Prefab' Block" class="text-center">
            <p class="mt-4">
              <i class="fas fa-info-square"></i> A pre styled component.
            </p>
          </group-input>

          <group-input label="Configuration">
            <div class="row">
              <div class="col-6 mb-3">
                <label class="form-label">Name</label>
                <select v-model="widget.name" class="form-select">
                  <option value="our_promise">Our Promise</option>
                  <option value="trust_pilot">Trust Pilot</option>
                  <option value="contact_us">Contact us Form</option>
                  <option value="newsletter_form">Newsletter Form</option>
                  <option value="glove_size_guide">Glove Size Guide</option>
                </select>
              </div>
              <div class="col-6" v-if="widget.name == 'trust_pilot'">
                <label class="form-label">Theme</label>
                <select v-model="widget.theme" class="form-select">
                  <option value="light">Light</option>
                  <option value="dark">Dark</option>
                </select>
              </div>
              <div class="col-6" v-if="widget.name == 'newsletter_form'">
                <label class="form-label">CTA</label>
                <input v-model="widget.cta" class="form-control" />
              </div>
              <div class="col-6" v-if="widget.name == 'newsletter_form'">
                <label class="form-label">Show terms and conditions</label>
                <select v-model="widget.showTerms" class="form-select">
                  <option value="true">yes</option>
                  <option value="false">no</option>
                </select>
              </div>
              <div class="col-6" v-if="widget.name == 'newsletter_form'">
                <label class="form-label">CTA style</label>
                <select v-model="widget.buttonType" class="form-select">
                  <option value="primary">primary</option>
                  <option value="secondary">secondary</option>
                  <option value="tertiary">tertiary</option>
                </select>
              </div>
            </div>
          </group-input>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>

<script>
import WidgetConfigModal from "./WidgetConfigModal";
import WidgetTemplate from "./WidgetTemplate.vue";

export default {
  components: {
    WidgetTemplate,
    WidgetConfigModal,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {};
  },
  methods: {
    config() {
      this.$refs["modal-" + this.id].show();
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.form-group .row {
  margin-top: 10px;
}
.form-preview-image {
  width: 250px;
  margin: 10px 0px;
}
</style>