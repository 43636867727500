<template>
  <div class="col-12 mt-4 background" :style="{
                      'background-image': 'url('+previewImage+'), linear-gradient(rgba('+image.rgba+'),rgba('+image.rgba+'))',
                      }">
    <div class="blur" :style="'backdrop-filter:blur('+image.blur+'px)'"></div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
export default {
  props: ["image"],
  computed: {
    previewImage() {
      if (this.image.desktop) {
        return this.image.desktop;
      }
      if (this.image.mobile) {
        return this.image.mobile;
      }
      return "https://res.cloudinary.com/mstore/image/fetch/v1634565390/https://staging.mstore.co.uk/wp-content/uploads/2020/04/SHO-APP-306-0613.jpg";
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  width: 100%;
  height: 180px;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
  position: relative;
}
.blur {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>