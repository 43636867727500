import { render, staticRenderFns } from "./WidgetTemplate.vue?vue&type=template&id=89cd4ebc&scoped=true&"
import script from "./WidgetTemplate.vue?vue&type=script&lang=js&"
export * from "./WidgetTemplate.vue?vue&type=script&lang=js&"
import style0 from "./WidgetTemplate.vue?vue&type=style&index=0&id=89cd4ebc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89cd4ebc",
  null
  
)

export default component.exports