
<template>
  <div class="h-100">
    <widget-template :id="id" name="Text With Image" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')">
      <span class="widget-text-content">
        <h1 v-if="this.widget.heading">{{this.widget.heading}}</h1>
      </span>
    </widget-template>
    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:title>Banner</template>
        <template v-slot:subtitle>{{ widget.label }}</template>
        <template v-slot:body>
          <group-input label="About 'Banner' Block" class="text-center">
            <p class="mt-4">
              <i class="fas fa-info-square"></i> A variable-width banner block with the ability to show text content along with an image on either side.
            </p>
          </group-input>

          <group-input label="Meta &amp; Presentation">
            <div class="row">
              <div class="col">
                <text-input v-model="widget.label" label="Label" />
                <p class="text-muted mt-2">
                  <i class="far fa-lightbulb"></i> Label is used for reference only and never shown on the front end.
                </p>
              </div>
              <div class="col">
                <theme-selector label="Theme" v-model="widget.theme" />
                <select-input label="Image Position" v-model="widget.imagePosition" :options="positionOptions" />
                <select-input label="Image Size" v-model="widget.imageSize" :options="sizeOptions" />
              </div>
            </div>
          </group-input>

          <div class="row">
            <div class="col">
              <group-input label="Image">
                <file-uploader v-model="widget.image" label="Image" />
              </group-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <group-input label="Content">
                <text-input label="Heading" v-model="widget.heading" />
                <wysiwyg-input v-model="widget.content" />
              </group-input>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetConfigModal from "./WidgetConfigModal";
import FileUploader from "./helpers/FileUploader";
import WidgetTemplate from "./WidgetTemplate.vue";

export default {
  components: {
    WidgetTemplate,
    WidgetConfigModal,
    FileUploader,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {
      categories: {},
      sizeOptions: [
        {
          label: "1/4",
          value: "3",
        },
        {
          label: "1/3",
          value: "4",
        },
        {
          label: "1/2",
          value: "6",
        },
        {
          label: "2/3",
          value: "8",
        },
      ],
      positionOptions: [
        {
          label: "Left",
          value: "left",
        },
        {
          label: "Right",
          value: "right",
        },
      ],
    };
  },
  methods: {
    config() {
      this.$refs["modal-" + this.id].show();
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.form-group .row {
  margin-top: 10px;
}
.form-preview-image {
  width: 250px;
  margin: 10px 0px;
}
.banner-content {
  color: white;
  background: black;
}
</style>