<template>
  <div class="form-group mt-2">
    <label v-if="label" :for="id">{{label}}</label>
    <multiselect v-if="ready" :id="id" v-model="selected" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" track-by="name" :label="type" :preselect-first="false">
      <template slot="selection" slot-scope="{ isOpen }">
        <span class="multiselect__single" v-if="selected && selected.length && !isOpen">{{ selected.length }} options selected</span>
      </template>
    </multiselect>
    <div v-else>Loading...</div>
  </div>
</template>

<script>
import axios from "./../../../http.js";
import Multiselect from "vue-multiselect";
import _ from "lodash";

export default {
  components: {
    Multiselect,
  },
  props: ["value", "type", "label"],
  data() {
    return {
      selected: _.cloneDeep(this.value),
      options: [],
      ready: false,
    };
  },
  watch: {
    selected(newTag) {
      this.$emit("input", newTag);
    },
    ready(val) {
      this.$emit("ready", val);
    },
  },
  computed: {
    id() {
      return "text_input_" + this._uid;
    },
  },
  mounted() {
    axios
      .get("/api/collections")
      .then((response) => {
        this.$nextTick(() => {
          let collections = [];
          response.data.data.forEach((element) => {
            // Note: we will use the id to identify the collections in the frontend,
            // the name and slug here are only for the label of the multiselector
            collections.push({
              id: element.id,
              name: element.name,
              slug: element.slug,
            });
          });
          this.ready = true;
          this.options = collections;
        });
      })
      .finally(() => {});
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.form-group .row {
  margin-top: 10px;
}
.form-preview-image {
  width: 250px;
  margin: 10px 0px;
}
.banner-content {
  color: white;
  background: black;
}
</style>
