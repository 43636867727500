var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"p-4 pt-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_c('select-input',{staticStyle:{"max-width":"200px"},attrs:{"label":"Type","options":[
        {value: 'text', label: 'Text'},
        {value: 'image', label: 'Image'},
      ]},model:{value:(_vm.layer.type),callback:function ($$v) {_vm.$set(_vm.layer, "type", $$v)},expression:"layer.type"}})],1)]),(_vm.layer.type === 'image')?_c('div',[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12"},[_c('file-uploader',{key:'file-uploader--'+this._uid,staticStyle:{"height":"200px"},attrs:{"label":"Image","type":"image"},model:{value:(_vm.layer.image.url),callback:function ($$v) {_vm.$set(_vm.layer.image, "url", $$v)},expression:"layer.image.url"}})],1),_c('div',{staticClass:"col-12 mt-4"},[_c('div',{staticClass:"row d-flex align-items-center"},[_c('div',{staticClass:"col-3"},[_c('select-input',{staticStyle:{"max-width":"200px"},attrs:{"label":"Position","options":[
            {value: 'center', label: 'Center'},
            {value: 'top', label: 'Top'},
            {value: 'bottom', label: 'Bottom'},
            {value: 'left', label: 'Left'},
            {value: 'right', label: 'Right'},
          ],"disabled":_vm.layer.image.position != '' &&
                _vm.layer.image.position != 'center' &&
                _vm.layer.image.position != 'top' &&
                _vm.layer.image.position != 'bottom' &&
                _vm.layer.image.position != 'left' &&
                _vm.layer.image.position != 'right'},model:{value:(_vm.layer.image.position),callback:function ($$v) {_vm.$set(_vm.layer.image, "position", $$v)},expression:"layer.image.position"}})],1),_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"help":"https://developer.mozilla.org/en-US/docs/Web/CSS/background-position","label":"Advanced Position options","type":"text"},model:{value:(_vm.layer.image.position),callback:function ($$v) {_vm.$set(_vm.layer.image, "position", $$v)},expression:"layer.image.position"}})],1)]),_c('div',{staticClass:"row d-flex align-items-center"},[_c('div',{staticClass:"col-3"},[_c('select-input',{staticStyle:{"max-width":"200px"},attrs:{"label":"Size","options":[
                {value: 'cover', label: 'Cover'},
                {value: 'contain', label: 'Contain'},
                {value: 'auto', label: 'Auto'},
              ],"disabled":_vm.layer.image.size != '' && _vm.layer.image.size != 'cover' && _vm.layer.image.size != 'contain' &&  _vm.layer.image.size != 'auto'},model:{value:(_vm.layer.image.size),callback:function ($$v) {_vm.$set(_vm.layer.image, "size", $$v)},expression:"layer.image.size"}})],1),_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"help":"https://developer.mozilla.org/en-US/docs/Web/CSS/background-size","label":"Advanced Size options","type":"text"},model:{value:(_vm.layer.image.size),callback:function ($$v) {_vm.$set(_vm.layer.image, "size", $$v)},expression:"layer.image.size"}})],1)]),_c('div',{staticClass:"row d-flex align-items-center"},[_c('div',{staticClass:"col-3"},[_c('select-input',{staticStyle:{"max-width":"200px"},attrs:{"label":"Repeat","options":[
                {value: 'no-repeat', label: 'No-Repeat'},
                {value: 'repeat', label: 'Repeat'},
                {value: 'repeat-x', label: 'Repeat-X'},
                {value: 'space', label: 'Space'},
              ],"disabled":_vm.layer.image.repeat != '' &&
            _vm.layer.image.repeat != 'no-repeat' &&
            _vm.layer.image.repeat != 'repeat' &&
            _vm.layer.image.repeat != 'repeat-x' &&
            _vm.layer.image.repeat != 'space'},model:{value:(_vm.layer.image.repeat),callback:function ($$v) {_vm.$set(_vm.layer.image, "repeat", $$v)},expression:"layer.image.repeat"}})],1),_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"help":"https://developer.mozilla.org/en-US/docs/Web/CSS/background-repeat","label":"Advanced Repeat options","type":"text"},model:{value:(_vm.layer.image.repeat),callback:function ($$v) {_vm.$set(_vm.layer.image, "repeat", $$v)},expression:"layer.image.repeat"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('select-input',{staticStyle:{"max-width":"200px"},attrs:{"label":"Blend Mode","options":[
                  {value: 'normal', label: 'Normal'},
                  {value: 'multiply', label: 'Multiply'},
                  {value: 'screen', label: 'Screen'},
                  {value: 'overlay', label: 'Overlay'},
                  {value: 'darken', label: 'Darken'},
                  {value: 'lighten', label: 'Lighten'},
                  {value: 'color-dodge', label: 'Color Dodge'},
                  {value: 'color-burn', label: 'Color Burn'},
                  {value: 'hard-light', label: 'Hard Light'},
                  {value: 'soft-light', label: 'Soft Light'},
                  {value: 'difference', label: 'Difference'},
                  {value: 'exclusion', label: 'Exclusion'},
                  {value: 'hue', label: 'Hue'},
                  {value: 'saturation', label: 'Saturation'},
                  {value: 'color', label: 'Color'},
                  {value: 'luminosity', label: 'Luminosity'},
                ]},model:{value:(_vm.layer.image.blendMode),callback:function ($$v) {_vm.$set(_vm.layer.image, "blendMode", $$v)},expression:"layer.image.blendMode"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"label":"Padding"},model:{value:(_vm.layer.image.padding),callback:function ($$v) {_vm.$set(_vm.layer.image, "padding", $$v)},expression:"layer.image.padding"}})],1),_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"label":"Margin"},model:{value:(_vm.layer.image.margin),callback:function ($$v) {_vm.$set(_vm.layer.image, "margin", $$v)},expression:"layer.image.margin"}})],1)])])])]):_vm._e(),(_vm.layer.type === 'text')?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"label":"Text"},model:{value:(_vm.layer.text.text),callback:function ($$v) {_vm.$set(_vm.layer.text, "text", $$v)},expression:"layer.text.text"}})],1),_c('div',{staticClass:"col-6"},[_c('select-input',{attrs:{"label":"Level","options":[
            {value: 'h1', label: 'H1'},
            {value: 'h2', label: 'H2'},
            {value: 'h3', label: 'H3'},
            {value: 'h4', label: 'H4'},
            {value: 'h5', label: 'H5'},
            {value: 'h6', label: 'H6'},
            {value: 'div', label: 'Paragraph'},
          ]},model:{value:(_vm.layer.text.level),callback:function ($$v) {_vm.$set(_vm.layer.text, "level", $$v)},expression:"layer.text.level"}})],1),_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"label":"Mobile Font Size"},model:{value:(_vm.layer.text.mobileFontSize),callback:function ($$v) {_vm.$set(_vm.layer.text, "mobileFontSize", $$v)},expression:"layer.text.mobileFontSize"}})],1),_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"label":"Desktop Font Size"},model:{value:(_vm.layer.text.desktopFontSize),callback:function ($$v) {_vm.$set(_vm.layer.text, "desktopFontSize", $$v)},expression:"layer.text.desktopFontSize"}})],1),_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"label":"Font Weight","help":"https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight"},model:{value:(_vm.layer.text.fontWeight),callback:function ($$v) {_vm.$set(_vm.layer.text, "fontWeight", $$v)},expression:"layer.text.fontWeight"}})],1),_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"label":"Letter Spacing"},model:{value:(_vm.layer.text.letterSpacing),callback:function ($$v) {_vm.$set(_vm.layer.text, "letterSpacing", $$v)},expression:"layer.text.letterSpacing"}})],1),_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"label":"Border Radius"},model:{value:(_vm.layer.text.borderRadius),callback:function ($$v) {_vm.$set(_vm.layer.text, "borderRadius", $$v)},expression:"layer.text.borderRadius"}})],1),_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"label":"Line Height"},model:{value:(_vm.layer.text.lineHeight),callback:function ($$v) {_vm.$set(_vm.layer.text, "lineHeight", $$v)},expression:"layer.text.lineHeight"}})],1),_c('div',{staticClass:"col-6"},[_c('align-selector',{attrs:{"label":"Alignment"},model:{value:(_vm.layer.text.textAlign),callback:function ($$v) {_vm.$set(_vm.layer.text, "textAlign", $$v)},expression:"layer.text.textAlign"}})],1),_c('div',{staticClass:"col-6"},[_c('select-input',{attrs:{"label":"Blend Mode","options":[
                        {value: 'normal', label: 'Normal'},
                        {value: 'multiply', label: 'Multiply'},
                        {value: 'screen', label: 'Screen'},
                        {value: 'overlay', label: 'Overlay'},
                        {value: 'darken', label: 'Darken'},
                        {value: 'lighten', label: 'Lighten'},
                        {value: 'color-dodge', label: 'Color Dodge'},
                        {value: 'color-burn', label: 'Color Burn'},
                        {value: 'hard-light', label: 'Hard Light'},
                        {value: 'soft-light', label: 'Soft Light'},
                        {value: 'difference', label: 'Difference'},
                        {value: 'exclusion', label: 'Exclusion'},
                        {value: 'hue', label: 'Hue'},
                        {value: 'saturation', label: 'Saturation'},
                        {value: 'color', label: 'Color'},
                        {value: 'luminosity', label: 'Luminosity'},
                      ]},model:{value:(_vm.layer.text.blendMode),callback:function ($$v) {_vm.$set(_vm.layer.text, "blendMode", $$v)},expression:"layer.text.blendMode"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"label":"Padding"},model:{value:(_vm.layer.text.padding),callback:function ($$v) {_vm.$set(_vm.layer.text, "padding", $$v)},expression:"layer.text.padding"}})],1),_c('div',{staticClass:"col-6"},[_c('text-input',{attrs:{"label":"Margin"},model:{value:(_vm.layer.text.margin),callback:function ($$v) {_vm.$set(_vm.layer.text, "margin", $$v)},expression:"layer.text.margin"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('label',[_vm._v("Text Colour")]),_c('colour-picker',{model:{value:(_vm.layer.text.color),callback:function ($$v) {_vm.$set(_vm.layer.text, "color", $$v)},expression:"layer.text.color"}})],1),_c('div',{staticClass:"col-6"},[_c('label',[_vm._v("Background Colour")]),_c('colour-picker',{model:{value:(_vm.layer.text.background),callback:function ($$v) {_vm.$set(_vm.layer.text, "background", $$v)},expression:"layer.text.background"}})],1)])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }