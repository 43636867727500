<template>
  <div style="height: 100%" :style="
      'text-align:' +
      widget.align +
      ';background-position:center;background-repeat: no-repeat;background-size: cover;background-image:url(' +
      widget.backgroundImage.desktop +
      ')'
    ">
    <div class="widget header handle" :style="'background:' + widget.colour">
      <div>
        <h5>
          <i :class="icon"></i> New Hero
        </h5>
        <div v-if="this.widget.label" style="font-size: 80%">{{ this.widget.label }}</div>
      </div>
      <widget-config-btn @click="config" />
      <widget-delete-btn @click="deleteSelf" />
      <widget-duplicate-btn @click="duplicate" />
    </div>
    <span class="widget-text-content">
      <h1 v-if="this.widget.heading">
        {{
        this.widget.heading
        }}
      </h1>
      <h2 v-if="this.widget.subheading">
        {{
        this.widget.subheading
        }}
      </h2>
    </span>
    <br />

    <div class="fakebuton" v-if="this.widget.cta">
      {{
      this.widget.cta
      }}
    </div>

    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:svg>
          <div class="w-full h-full flex items-center justify-center">
            <i class="text-white" :class="icon" style="font-size: 40px"></i>
          </div>
        </template>
        <template v-slot:title>Hero</template>
        <template v-slot:subtitle>{{ widget.label }}</template>
        <template v-slot:body>
          <group-input label="About 'New Hero' Block" class="text-center">
            <p class="mt-4">
              <i class="fas fa-info-square"></i> A full-width hero block with the ability to show a background image, text content and a customisable CTA.
            </p>
            <p class="text-muted mt-2">
              <i class="far fa-lightbulb"></i> This block is
              <i>always</i> the full-width of the page.
            </p>
          </group-input>
          <group-input label="Meta &amp; Presentation">
            <div class="row">
              <div class="col">
                <text-input v-model="widget.label" label="Label" />
                <p class="text-muted mt-2">
                  <i class="far fa-lightbulb"></i> Label is used for reference only and never shown on the front end.
                </p>
              </div>
              <div class="col">
                <align-selector label="Alignment" v-model="widget.align" :options="[{value: 'left', label: 'Left'},{value: 'right', label: 'Right'}]" />
              </div>
            </div>
          </group-input>

          <group-input label="Design">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <file-uploader v-model="widget.backgroundImage.desktop" label="Desktop background image" style="height:200px;" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <file-uploader v-model="widget.backgroundImage.mobile" label="Mobile background image" style="height:200px;" />
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-6">
                <div class="form-group">
                  <label for>Colour Overlay</label>
                  <colour-picker v-model="widget.backgroundImage.rgba" />
                </div>
              </div>
              <div class="col-6 mt-4">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for>Background Blur</label>
                      <div class="d-flex">
                        <input type="range" min="0" max="20" v-model="widget.backgroundImage.blur" class="form-range me-2" />
                        <div>{{widget.backgroundImage.blur}}px</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-4">
                    <previewImage :image="widget.backgroundImage" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-6">
                <div class="form-group">
                  <div style="cursor:pointer;float:right;" @click="widget.foregroundImage = null" v-if="widget.foregroundImage">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </div>
                  <file-uploader v-model="widget.foregroundImage" label="Foreground image" style="height:200px;" />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div style="cursor:pointer;float:right;" @click="widget.ctaImage = null" v-if="widget.ctaImage">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </div>
                  <file-uploader v-model="widget.ctaImage" label="Image above CTA" style="height:200px;" />
                </div>
              </div>
            </div>
          </group-input>
          <div class="row">
            <div class="col">
              <group-input label="Content">
                <text-input label="Heading" v-model="widget.heading" />
                <text-input label="Subheading" v-model="widget.subheading" />
                <text-input label="Content" v-model="widget.content" />
                <checkbox-input label="Underline Heading" v-model="widget.underlineHeading" />
                <checkbox-input label="Full Width" v-model="widget.fullWidth" />
              </group-input>
            </div>
            <div class="col">
              <group-input label="Call to Action">
                <text-input label="Text" v-model="widget.cta" />
                <label class="block text-sm flex items-center" for="type">Type</label>
                <select class="form-select" name="type" v-model="widget.action.name">
                  <option value disabled selected>-</option>
                  <option value="category">Category</option>
                  <option value="page">Page</option>
                  <option value="link">Link</option>
                </select>
                <text-input class="mt-2" label="Link" v-if="widget.action.name=='link'" v-model="widget.action.params.link" />
                <input type="hidden" v-if="widget.action.name=='category'" v-model="widget.action.name" />
                <categories-select v-if="widget.action.name=='category'" class="mt-2" v-model="widget.action.params.category" label="Action params category" />
                <pages-select v-if="widget.action.name=='page'" class="mt-2" v-model="widget.action.params.page" label="Action page" />
                <cta-selector label="Button Type" v-model="widget.buttonType" />
                <checkbox-input class="mt-2" label="Open in a new tab" v-model="widget.action.params.targetBlank" />
              </group-input>
            </div>
          </div>
          <input type="hidden" name="action-params-react" v-model="widget.action.params.react" value="yes" />
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-red" @click="deleteSelf">Delete</button>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import WidgetConfigBtn from "./WidgetConfigBtn";
import WidgetConfigModal from "./WidgetConfigModal";
import FileUploader from "./helpers/FileUploader";
import WidgetDuplicateBtn from "./WidgetDuplicateBtn";
import WidgetDeleteBtn from "./WidgetDeleteBtn";
import CategoriesSelect from "./helpers/Categories";
import PagesSelect from "./helpers/Pages";
import { Chrome } from "vue-color";
import PreviewImage from "./helpers/PreviewImage";
import ColourPicker from "./inputs/colour-picker";

export default {
  components: {
    WidgetConfigBtn,
    WidgetConfigModal,
    FileUploader,
    WidgetDuplicateBtn,
    WidgetDeleteBtn,
    CategoriesSelect,
    PagesSelect,
    Chrome,
    PreviewImage,
    ColourPicker,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {
      categories: {},
      ctaType: "category",
    };
  },
  methods: {
    deleteSelf() {
      if (confirm("Do you really want to delete?")) {
        this.$refs["modal-" + this.id].hide();
        this.$emit("delete");
      }
    },
    config() {
      this.$refs["modal-" + this.id].show();
    },
    duplicate() {
      if (confirm("duplicate item?")) {
        this.$emit("duplicate");
      }
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.form-group .row {
  margin-top: 10px;
}
.form-preview-image {
  width: 250px;
  margin: 10px 0px;
}
</style>