
<template>
  <div class="h-100">
    <widget-template :id="id" name="Image" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')">
      <div class="widget-image-content" :style="'background-image: url(' + widget.image + ');'">
        <span class="widget-text-content">{{this.widget.alt}}</span>
      </div>
    </widget-template>

    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:title>Image</template>
        <template v-slot:subtitle>{{ widget.label }}</template>
        <template v-slot:body>
          <group-input label="About 'Banner' Block" class="text-center">
            <p class="mt-4">
              <i class="fas fa-info-square"></i> An image.
            </p>
          </group-input>

          <group-input label="Meta &amp; Presentation">
            <div class="row">
              <div class="col-6">
                <text-input v-model="widget.alt" label="Image ALT" />
                <p class="mt-2">
                  <i class="far fa-lightbulb"></i> This is important for SEO.
                </p>
              </div>
            </div>
          </group-input>

          <div class="row">
            <div class="col">
              <group-input label="Image">
                <file-uploader v-model="widget.image" label="Image" folder="page" />
              </group-input>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetConfigModal from "./WidgetConfigModal";
import FileUploader from "./helpers/FileUploader";
import WidgetTemplate from "./WidgetTemplate.vue";

export default {
  components: {
    WidgetTemplate,
    WidgetConfigModal,
    FileUploader,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {
      categories: {},
      sizeOptions: [
        {
          label: "1/4",
          value: "3",
        },
        {
          label: "1/3",
          value: "4",
        },
        {
          label: "1/2",
          value: "6",
        },
        {
          label: "2/3",
          value: "8",
        },
      ],
      positionOptions: [
        {
          label: "Left",
          value: "left",
        },
        {
          label: "Right",
          value: "right",
        },
      ],
    };
  },
  methods: {
    config() {
      this.$refs["modal-" + this.id].show();
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.form-group .row {
  margin-top: 10px;
}
.form-preview-image {
  width: 250px;
  margin: 10px 0px;
}
.banner-content {
  color: white;
  background: black;
}
.widget-image-content {
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>