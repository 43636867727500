<template>
  <div class="h-100">
    <widget-template :id="id" name="Carbon Calculator" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')"  :style="
      'text-align:' +
      widget.align +
      ';background-position:center;background-repeat: no-repeat;background-size: cover;'+
      'background-image:url(\'https://res.cloudinary.com/globusgroup/image/upload/c_limit,w_2000,f_auto/globus_static_assets/globusgroup/image/GG-BAN-RESOURCES-0122_V2_2.jpg\');'
      ">
      <div class="w-full h-full bg-plastic"></div>
    </widget-template>

    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:svg>
          <div class="w-full h-full flex items-center justify-center">
            <i class="text-white" :class="icon" style="font-size: 40px"></i>
          </div>
        </template>
        <template v-slot:title>Carbon Calculator Configuration</template>
        <template v-slot:body>
          <group-input label="About 'Carbon Calculator' Block" class="text-center">
            <p class="mt-4">
              <i class="fas fa-info-square"></i> Carbon calculator widget
            </p>
          </group-input>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetTemplate from "./WidgetTemplate.vue";
import WidgetConfigModal from "./WidgetConfigModal";

export default {
  components: {
    WidgetTemplate,
    WidgetConfigModal,
  },
  props: ["widget", "icon", "id"],
  methods: {
    config() {
      this.$refs["modal-" + this.id].show();
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
};
</script>