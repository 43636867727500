<template>
  <div>
    <div class="layer-container" :id="'layer-container_' + this.component_key">
      <div v-for="background in backgrounds" :class="'layer_'+component_key+' layer_dropzone'" :id="'layer_'+component_key+'_'+background.id" :key="background.id" draggable="true" :data-id="background.id" class="layer">
        <div class="mainlayer">
          <div class="handle d-flex justify-content-between" :class="{'inactive': background.active !== true}">
            <div class="d-flex align-items-center">
              <div class="icon dragicon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
                  />
                </svg>
              </div>
              <span class="background-type">{{background.config.type}}</span>
              <div v-if="background.config.type =='text'" contenteditable="false">
                {{
                background.config.text.text.replace(/(\\n)/gm, " ").substring(0, 40) + (background.config.text.text.length > 40 ? "..." : "")
                }}
              </div>
              <div v-else class="layer-label" contenteditable="true" :data-id="background.id" @input="updateLabel" :ref="'editableBackgroundLabel'+background.id">...</div>
            </div>
            <div class="commands d-flex align-items-center">
              <div class="command" @click="toggleLayer(background.id)">
                <chevron :key="'chevronicon_'+ component_key + '_' + background.id " :ref="'chevronicon_' + component_key + '_' + background.id" />
              </div>

              <div v-if="background.active === true" class="command eye" @click="toggleLayerActive(background.id)">
                <svg class="svg-inline--fa fa-copy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                  <path
                    d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"
                  />
                </svg>
              </div>
              <div v-else class="command inactive" @click="toggleLayerActive(background.id)">
                <svg class="svg-inline--fa fa-copy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                  <path
                    d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z"
                  />
                </svg>
              </div>

              <div class="command" @click="copyLayerToOtherManager(background.id)">
                <i class="fa-regular fa-file-import"></i>
              </div>

              <div class="command" @click="cloneLayer(background.id)">
                <svg class="svg-inline--fa fa-copy" style="color: rgb(255, 255, 255);" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="copy" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg>
                  <path d="M224 0c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224zM64 160c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64H64V224h64V160H64z" />
                </svg>
              </div>
              <div class="command" @click="deleteLayer(background.id)">
                <svg class="svg-inline--fa fa-trash-can" style="color: rgb(255, 255, 255);" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-can" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg>
                  <path
                    d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="layer-content" v-if="type == 'background'">
            <backgrounds-input :key="'bg-input-component-'+background.id" :uuid="component_key" v-model="background.config" />
          </div>
          <div class="layer-content" v-if="type == 'content'">
            <content-input :key="'bg-input-component-'+background.id" :uuid="component_key" v-model="background.config" />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <button class="vave-btn btn-green mt-2" @click="addLayer">Add {{type == 'content' ? 'Content' : 'Layer' }}</button>
    </div>
  </div>
</template>

<script>
import BackgroundsInput from "./../inputs/backgrounds-input.vue";
import ContentInput from "./../inputs/content-input.vue";
import { getCurrentInstance } from "vue";
import availableWidgets from "../../../availableWidgets";
import Chevron from "./Chevron.vue";

export default {
  name: "LayersManager",
  props: {
    value: Array,
    uuid: String,
    type: String,
  },
  components: {
    BackgroundsInput,
    ContentInput,
    Chevron,
  },
  data() {
    return {
      draggedLayer: null,
      backgrounds: this.value,
      key: null,
    };
  },
  mounted() {
    this.makeReactive();
    this.setLayerLables();
    this.key = getCurrentInstance().proxy.$vnode.key;
  },
  computed: {
    component_key() {
      return this.uuid + "_" + getCurrentInstance().proxy.$vnode.key;
    },
  },
  methods: {
    setLayerLables() {
      // We need this workaround to avoid a loop in Vue updating the model and messing with the cursor position
      var layerContainer = document.getElementById(
        "layer-container_" + this.component_key
      );
      if (!layerContainer) return;
      var layers = layerContainer.querySelectorAll(
        ".layer_" + this.component_key
      );
      layers.forEach((layer) => {
        const layerLabel = layer.querySelector(".layer-label");
        if (!layerLabel) return;
        // listen to the Enter key, and focus out
        layerLabel.addEventListener("keydown", (event) => {
          if (event.key === "Enter") event.target.blur();
        });
        layer = this.backgrounds.find((bg) => bg.id == layer.dataset.id);
        if (layer.config.type == "text") {
          // Trim the text to 20 characters
          let content = layer.config.text.text;
          // remove \n
          content = content.replace(/(\\n)/gm, " ");
          layerLabel.innerHTML =
            content.substring(0, 40) + (content.length > 40 ? "..." : "");
        } else {
          layerLabel.innerHTML = layer.label;
        }
      });
    },
    makeReactive() {
      const layerContainer = document.getElementById(
        "layer-container_" + this.component_key
      );

      // Add event listeners to the layers for drag and drop functionality
      if (!layerContainer) return;
      const layers = layerContainer.querySelectorAll(
        ".layer_" + this.component_key
      );
      this.draggedLayer = null;

      layers.forEach((layer) => {
        // check if the layer dataset isreactive is already set
        if (layer.dataset.isreactive) return;
        layer.dataset.isreactive = true;

        layer.addEventListener("dragstart", (event) => {
          this.draggedLayer = event.target;
          event.target.classList.add("dragging");
        });

        layer.addEventListener("dragend", (event) => {
          event.preventDefault();
          // Remove the dragging class
          event.target.classList.remove("dragging");

          // Update the backgrounds array with the new order of layers
          this.backgrounds.sort((a, b) => {
            const layerA = layerContainer.querySelector(`[data-id="${a.id}"]`);
            const layerB = layerContainer.querySelector(`[data-id="${b.id}"]`);
            return (
              Array.from(layerContainer.children).indexOf(layerA) -
              Array.from(layerContainer.children).indexOf(layerB)
            );
          });
        });

        layer.addEventListener("dragover", (event) => {
          event.preventDefault();
          const dropZone = event.target.closest(".layer");

          if (!dropZone || dropZone === this.draggedLayer) {
            return;
          }

          const dropZoneY = dropZone.getBoundingClientRect().y;
          const draggedLayerY = this.draggedLayer.getBoundingClientRect().y;

          if (draggedLayerY < dropZoneY) {
            layerContainer.insertBefore(
              this.draggedLayer,
              dropZone.nextSibling
            );
          } else {
            layerContainer.insertBefore(this.draggedLayer, dropZone);
          }
        });
      });
    },
    updateLabel(e) {
      // We need this workaround to avoid a loop in Vue updating the model and messing with the cursor position
      let layer = this.backgrounds.find(
        (background) => background.id == e.target.dataset.id
      );
      layer.label =
        this.$refs["editableBackgroundLabel" + layer.id][0].textContent;
    },
    addLayer(PointerEvent, copiedLayer = null) {
      let maxId = 0;
      this.backgrounds.forEach((background) => {
        if (background.id > maxId) {
          maxId = background.id;
        }
      });

      // find the widget with "widget.contains" == "custom-banner" so we can get the default layer
      let defaultWidget = availableWidgets.find(
        (widget) => widget.contains == "custom-banner"
      );
      let defaultLayer = JSON.parse(
        JSON.stringify(
          defaultWidget.widgetConfig[
            this.type == "content" ? "content" : "background"
          ][0]
        )
      );

      let newLayer = copiedLayer ? copiedLayer : defaultLayer;
      newLayer.id = maxId + 1;

      this.backgrounds = [...this.backgrounds, newLayer];
      // on next tick
      this.$nextTick(() => {
        this.makeReactive();
      });
    },
    deleteLayer(id) {
      if (!confirm("Are you sure you want to delete this layer?")) {
        return;
      }
      this.backgrounds = this.backgrounds.filter((background) => {
        return background.id !== id;
      });
    },
    cloneLayer(id) {
      const layer = this.backgrounds.find((background) => {
        return background.id === id;
      });
      let clonedLayer = JSON.parse(JSON.stringify(layer));
      let maxId = 0;
      this.backgrounds.forEach((background) => {
        if (background.id > maxId) {
          maxId = background.id;
        }
      });
      this.backgrounds = [
        ...this.backgrounds,
        {
          id: maxId + 1,
          active: clonedLayer.active,
          label: clonedLayer.label,
          masks: clonedLayer.masks,
          config: clonedLayer.config,
        },
      ];
      // on next tick
      this.$nextTick(() => {
        this.makeReactive();
      });
    },
    copyLayerToOtherManager(id) {
      const layer = this.backgrounds.find((background) => {
        return background.id === id;
      });
      let target =
        this.key == "layers_manager_desktop"
          ? "layers_manager_mobile"
          : "layers_manager_desktop";
      let data = { target: target, layer: JSON.parse(JSON.stringify(layer)) };
      this.$emit("copyLayerToTarget", data);
    },
    toggleLayerActive(id) {
      const layer = this.backgrounds.find((background) => {
        return background.id === id;
      });
      layer.active = !layer.active;
    },
    toggleLayer(id) {
      const layerContainer = document.getElementById(
        "layer-container_" + this.component_key
      );

      let layer = layerContainer.querySelector(
        "#layer_" + this.component_key + "_" + id
      );

      layer.querySelector(".handle").classList.toggle("expanded");
      layer.querySelector(".layer-content").classList.toggle("expanded");
      this.$refs["chevronicon_" + this.component_key + "_" + id][0].flip();

      // Toggle the draggable attribute from the layer
      layer.setAttribute(
        "draggable",
        layer.getAttribute("draggable") == "true" ? "false" : "true"
      );

      if (!layer.querySelector(".dragicon")) {
        return;
      }

      // Hide the drag handle if the layer is not draggable
      if (layer.getAttribute("draggable") == "false") {
        layer.querySelector(".dragicon").classList.add("hidden");
      } else {
        layer.querySelector(".dragicon").classList.remove("hidden");
      }
    },
  },
  watch: {
    backgrounds() {
      this.$emit("input", this.backgrounds);
      // on next tick
      this.$nextTick(() => {
        this.setLayerLables();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.layer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  .layer {
    width: 100%;
    &:not(:first-child) {
      margin-top: 10px;
    }

    .mainlayer {
      width: 100%;
      min-width: 300px;

      .handle {
        border: 1px solid #ccc;

        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: #f0f0f0;
        transition: all 0.3s ease-in-out;
        &:not(.expanded) {
          transition: all 0.3s ease-in-out;
          border-bottom: 1px solid transparent;
        }
        &.inactive {
          color: #ccc;
        }

        .background-type {
          text-transform: uppercase;
          font-size: 0.7em;
          font-weight: 800;
          color: #bbb;
          letter-spacing: -0.05em;
          position: relative;
          top: 1px;
          width: 80px;
          margin-left: 20px;
        }
      }

      .layer-content {
        border: 1px solid #ccc;
        background-color: #f0f0f0;

        border-top: 0;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s ease-in-out;

        &.expanded {
          max-height: 1500px;
        }
      }
    }
  }
}

.dragging {
  opacity: 0.5;
}
.icon {
  fill: #aaa;
  color: #aaa;
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  width: 18px;

  &.dragicon {
    cursor: move;
    &.hidden {
      cursor: default;
      opacity: 0;
    }
  }
}
.commands {
  padding: 5px;
  cursor: pointer;
  .command {
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    fill: #888;
    color: #888;

    &.eye {
      svg {
        position: relative;
        left: 1px;
      }
    }
    &.inactive {
      fill: #d61212;
      color: #d61212;
    }

    &:not(:last-child) {
      margin-right: 5px;
    }

    &:hover {
      background: #e0e0e0;
    }
  }
}
.padlock {
  color: #888;
}
</style>