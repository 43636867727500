
<template>
  <div>
    <chrome style="width:100%;max-width:300px;" :value="rgba" @input="updateColour"></chrome>
  </div>
</template>
<script>
import { Chrome } from "vue-color";

export default {
  components: {
    Chrome,
  },
  props: ["value"],
  watch: {
    value(value) {
      this.rgba = this.splitRgba(value);
    },
  },
  data() {
    return {
      rgba: this.splitRgba(this.value),
    };
  },
  methods: {
    updateColour(val) {
      this.$emit("input", this.joinRgba(val.rgba));
    },
    splitRgba(colour) {
      if (colour == undefined) {
        return {};
      }
      let split = colour.split(",");
      return {
        r: split[0],
        g: split[1],
        b: split[2],
        a: split[3],
      };
    },
    joinRgba(colour) {
      return colour.r + "," + colour.g + "," + colour.b + "," + colour.a;
    },
  },
};
</script>
