
<template>
  <div class="h-100">
    <widget-template :id="id" name="Brands" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')">
      <div class="d-flex align-items-center" style="height:130px;">
        <div class="m-1 p-2 w-100 d-flex justify-content-evenly align-items-center">
          <div v-for="(brand,k) in widget.brands" :key="k">
            <img :src="brand.logoUrl" style="max-width: 130px;" />
          </div>
        </div>
      </div>
    </widget-template>

    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:title>Image</template>
        <template v-slot:body>
          <group-input label="About 'Brands' Block" class="text-center">
            <p class="mt-4">
              <i class="fas fa-info-square"></i> List of brands with logos and link to page.
            </p>
          </group-input>

          <group-input label="Brands">
            <div class="row">
              <div class="col">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Brand</th>
                      <th>Logo</th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(brand,k) in widget.brands" :key="k" style="height: 50px;">
                      <td>{{brand.label}}</td>
                      <td class="px-4">
                        <img :src="brand.logoUrl" style="max-width: 130px;" />
                      </td>
                      <td>
                        <a target="_blank" :href="pwaUrl + '/products/brands/' + brand.label">{{brand.label}}</a>
                      </td>
                      <td>
                        <button class="btn btn-sm btn-secondary me-1" @click="moveBrandUp(k)" :disabled="k == 0">
                          <i class="fa-solid fa-arrow-up"></i>
                        </button>
                        <button class="btn btn-sm btn-secondary me-1" @click="moveBrandDown(k)" :disabled="k == (Object.keys(widget.brands).length - 1)">
                          <i class="fa-solid fa-arrow-down"></i>
                        </button>
                      </td>
                      <td>
                        <button class="btn btn-sm btn-danger" @click="removeBrand(k)">
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </group-input>

          <group-input label="Add Brand">
            <div class="row d-flex align-items-end">
              <div class="col-4">
                <div class="form-group">
                  <file-uploader v-model="newLogoImageUrl" label="New Logo" style="width:100%;border:1px solid lightgray;" />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <text-input v-model="newLogoLabel" label="Logo Label" />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <button class="vave-btn btn-green" @click="addBrand" :disabled="newLogoLabel == null || newLogoImageUrl == null">Add</button>
                </div>
              </div>
            </div>
          </group-input>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetConfigModal from "./WidgetConfigModal";
import FileUploader from "./helpers/FileUploader";
import WidgetTemplate from "./WidgetTemplate.vue";

export default {
  components: {
    WidgetTemplate,
    WidgetConfigModal,
    FileUploader,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {
      pwaUrl: process.env.VUE_APP_PWAURL,
      newLogoImageUrl: null,
      newLogoLabel: null,
    };
  },
  methods: {
    addBrand() {
      this.widget.brands.push({
        label: this.newLogoLabel,
        logoUrl: this.newLogoImageUrl,
      });
      this.newLogoLabel = null;
      this.newLogoImageUrl = null;
    },
    moveBrandDown(k) {
      if (k < this.widget.brands.length - 1) {
        var temp = this.widget.brands[k];
        this.widget.brands.splice(k, 1);
        this.widget.brands.splice(k + 1, 0, temp);
      }
    },
    moveBrandUp(k) {
      if (k > 0) {
        var temp = this.widget.brands[k];
        this.widget.brands.splice(k, 1);
        this.widget.brands.splice(k - 1, 0, temp);
      }
    },
    removeBrand(k) {
      if (confirm("are you sure?")) {
        this.widget.brands.splice(k, 1);
      }
    },
    config() {
      this.$refs["modal-" + this.id].show();
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.form-group .row {
  margin-top: 10px;
}
</style>