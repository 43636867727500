const uuidv4 = require("uuid/v4");
export default
    [
        {
            x: 0,
            y: 0,
            w: 12,
            h: 1,
            isResizable: true,
            i: uuidv4(),
            icon: "fa-solid fa-paintbrush-pencil",
            contains: "custom-banner",
            description: "Add a Banner with text, image and cta. All fields should be more customisable.",
            widgetConfig: {
                debug: false,
                fullWidth: false,
                layout: {
                    width: 12,
                    height: "450px",
                    mobileHeight: "450px",
                    containerAlign: "left",
                    contentAlign: "left",
                    verticalAlign: "center",
                    padding: "30px",
                    requiresContainer: false,
                },
                background: [
                    {
                        id: 1,
                        active: true,
                        label: "Layer",
                        config: {
                            type: "colour",
                            color: "200,200,200,1",
                            opacity: 1,
                            gradient: {
                                from: "255, 0, 0, 1",
                                fromPercentage: "10",
                                to: "0, 0, 255, 1",
                                toPercentage: "100",
                                angle: "90",
                            },
                            image: {
                                url: "https://res.cloudinary.com/mstore/image/upload/v1649417894/vaveproxy/products/vave_aee20c86474f2a5f28dafb6aa96ea5ac.png",
                                position: "center",
                                size: "cover",
                                repeat: "no-repeat",
                            },
                            video: {
                                url: "https://globusgroup.ams3.cdn.digitaloceanspaces.com/globusgroup/video/riley_brand_video_2023-1080p.mp4",
                                offset: 0,
                            },
                            blendMode: "normal",
                            blur: "0",
                            mask: {
                                active: false,
                                url: "https://res.cloudinary.com/globusgroup/image/upload/v1673386266/logo-black_j96uyo.png",
                                repeat: "no-repeat",
                                position: "center 150px",
                                size: "40%",
                            },
                            parallax: {
                                active: false,
                                scale: 1.2,
                                translateZ: '-100px',
                            },
                        },
                    },
                ],
                mobile_background: [
                    {
                        id: 1,
                        active: true,
                        label: "Layer",
                        config: {
                            type: "colour",
                            color: "200,200,200,1",
                            opacity: 1,
                            gradient: {
                                from: "255, 0, 0, 1",
                                fromPercentage: "10",
                                to: "0, 0, 255, 1",
                                toPercentage: "100",
                                angle: "90",
                            },
                            image: {
                                url: "https://res.cloudinary.com/mstore/image/upload/v1649417894/vaveproxy/products/vave_aee20c86474f2a5f28dafb6aa96ea5ac.png",
                                position: "center",
                                size: "cover",
                                repeat: "no-repeat",
                            },
                            video: {
                                url: "https://globusgroup.ams3.cdn.digitaloceanspaces.com/globusgroup/video/riley_brand_video_2023-1080p.mp4",
                            },
                            blendMode: "normal",
                            blur: "0",
                            mask: {
                                active: false,
                                url: "https://res.cloudinary.com/globusgroup/image/upload/v1673386266/logo-black_j96uyo.png",
                                repeat: "no-repeat",
                                position: "center 150px",
                                size: "40%",
                            },
                            parallax: {
                                active: false,
                                scale: 1.2,
                                translateZ: '-100px',
                            },
                        },
                    },
                ],
                content: [
                    {
                        id: 1,
                        active: true,
                        label: "Content Block",
                        config: {
                            type: "text",
                            text: {
                                text: "Lorem Ipsum",
                                level: "h1",
                                textAlign: "left",
                                blendMode: "normal",
                                color: "0,0,0,1",
                                background: "0,0,0,0",
                                borderRadius: "0",
                                padding: "0px",
                                margin: "0 0 20px",
                                desktopFontSize: "48px",
                                mobileFontSize: "35px",
                                fontWeight: "400",
                                letterSpacing: "0.0em",
                                lineHeight: "1em",
                            },
                            image: {
                                url: "https://res.cloudinary.com/globusgroup/image/upload/v1673386266/logo-black_j96uyo.png",
                                position: "center",
                                size: "contain",
                                repeat: "no-repeat",
                                height: "70px",
                                blendMode: "normal",
                                padding: "30px",
                                margin: "0px",
                            },
                        },
                    },
                ],
                cta: {
                    hasButton: false,
                    wholeBannerClickable: false,
                    button: {
                        type: "primary",
                        text: "",
                        style: {
                            align: "left",
                            bg: "255,0,0,1",
                            bgHover: "200,0,0,1",
                            text: "255,255,255,1",
                            textHover: "0,0,0,1",
                            border: "255,0,0,1",
                            borderHover: "200,0,0,1",
                        }
                    },
                    action: {
                        name: "link",
                        params: {
                            category: "",
                            page: "",
                            link: "/",
                            react: true,
                        },
                    },
                },
            },
        },
        {
            x: 0,
            y: 0,
            w: 6,
            h: 1,
            i: uuidv4(),
            icon: "fa fa-image",
            contains: "banner",
            description: "Add a banner with text, image and cta",
            widgetConfig: {
                backgroundImage: {
                    mobile: "",
                    desktop: "",
                    rgba: "0,0,0,0",
                    blur: 0,
                },
                heading: "",
                subheading: "",
                cta: "",
                align: "left",
                theme: "light",
                buttonType: "secondary",
                action: {
                    name: "",
                    params: {
                        page: "",
                        category: "",
                        react: true,
                    },
                },
            },
        },
        {
            x: 0,
            y: 0,
            w: 12,
            h: 1,
            i: uuidv4(),
            icon: "fa fa-image",
            contains: "new-banner",
            isResizable: false,
            description: "Add a banner with text, background and foreground images, and cta",
            widgetConfig: {
                backgroundImage: {
                    mobile: "",
                    desktop: "",
                    rgba: "0,0,0,0",
                    blur: 0,
                },
                centerImage: "",
                sideImage: "",
                heading: "",
                subheading: "",
                cta: "",
                align: "left",
                theme: "light",
                buttonType: "secondary",
                action: {
                    name: "",
                    params: {
                        page: "",
                        link: "",
                        category: "",
                        react: true,
                    },
                },
            },
        },
        {
            x: 0,
            y: 0,
            w: 6,
            h: 1,
            i: uuidv4(),
            icon: "fa fa-image",
            contains: "textWithImage",
            description: "Text with image on one side",
            widgetConfig: {
                image: "",
                heading: "",
                content: "",
                imagePosition: "left",
                imageSize: "3",
                theme: "light",
            },
        },
        {
            x: 0,
            y: 0,
            w: 6,
            h: 1,
            i: uuidv4(),
            icon: "fa fa-image",
            contains: "imageBlock",
            description: "Just an image",
            widgetConfig: {
                image: "",
                alt: ""
            },
        },
        {
            x: 0,
            y: 0,
            w: 6,
            h: 1,
            i: uuidv4(),
            icon: "fa fa-arrow-turn-down-left",
            contains: "spacer",
            description: "Space",
            widgetConfig: {
                image: "",
                alt: ""
            },
        },
        {
            x: 0,
            y: 0,
            w: 6,
            h: 1,
            i: uuidv4(),
            icon: "fa fa-hand-paper",
            contains: "products",
            description: "Products",
            widgetConfig: {
                type: "",
                category: "",
                tags: null,
                numberOfProducts: 12,
                skus: [],
                products: [],
                collections: [],
                attributes: [],
                orderBy: "strategy",
                orderingStrategy: "_default",
                theme: "carousel",
            },
        },
        {
            x: 0,
            y: 0,
            w: 6,
            h: 1,
            i: uuidv4(),
            icon: "fa-solid fa-comments-question",
            contains: "faqs",
            description: "Add a list of selected FAQs",
            widgetConfig: {
                heading: "",
                faqs: [],
                type: "orphan",
                category: "",
            },
        },
        {
            x: 0,
            y: 0,
            w: 6,
            h: 1,
            i: uuidv4(),
            icon: "fa fa-envelope",
            contains: "active-campaign",
            description: "Add an Active Campain form",
            widgetConfig: {
                heading: "",
                subheading: "",
                activeCampaignId: "",
            },
        },
        {
            x: 0,
            y: 0,
            w: 6,
            h: 1,
            i: uuidv4(),
            icon: "fa fa-file-word",
            contains: "text-editor",
            description: "Text editor",
            widgetConfig: {
                content: "",
                theme: "light",
            },
        },
        {
            x: 0,
            y: 0,
            w: 6,
            h: 1,
            i: uuidv4(),
            icon: "fa-thin fa-bottle-water",
            contains: "carbon-calculator",
            description: "Carbon Calculator",
            widgetConfig: {
                "label": 'Click to edit label',
            },
        },
        {
            x: 0,
            y: 0,
            w: 12,
            h: 1,
            isResizable: false,
            i: uuidv4(),
            icon: "fa fa-mask",
            contains: "hero",
            description: "Add a Hero with text, image and cta",
            widgetConfig: {
                backgroundImage: {
                    mobile: "",
                    desktop: "",
                    rgba: "0,0,0,0",
                    blur: 0,
                },
                heading: "",
                subheading: "",
                cta: "",
                align: "left",
                theme: "light",
                buttonType: "primary",
                action: {
                    name: "",
                    params: {
                        category: "",
                        page: "",
                        react: true,
                    },
                },
            },
        },
        {
            x: 0,
            y: 0,
            w: 12,
            h: 1,
            isResizable: false,
            i: uuidv4(),
            icon: "fa fa-mask",
            contains: "new-hero",
            description: "Add a Hero with text, background image, foreground image and a cta",
            widgetConfig: {
                fullWidth: true,
                backgroundImage: {
                    mobile: "",
                    desktop: "",
                    rgba: "0,0,0,0",
                    blur: 0,
                },
                foregroundImage: "",
                ctaImage: "",
                heading: "",
                subheading: "",
                underlineHeading: true,
                content: "",
                cta: "",
                align: "left",
                theme: "light",
                buttonType: "primary",
                action: {
                    name: "",
                    params: {
                        category: "",
                        page: "",
                        react: true,
                    },
                },
            },
        },
        {
            x: 0,
            y: 0,
            w: 12,
            h: 1,
            i: uuidv4(),
            isResizable: false,
            icon: "fab fa-pied-piper-alt",
            contains: "blog",
            description: "Blog",
            widgetConfig: {
            },
        },
        {
            x: 0,
            y: 0,
            w: 12,
            h: 1,
            i: uuidv4(),
            isResizable: false,
            icon: "fa-solid fa-copyright",
            contains: "brands",
            description: "Brands",
            widgetConfig: {
                brands: []
            },
        },
        {
            x: 0,
            y: 0,
            w: 12,
            h: 1,
            i: uuidv4(),
            isResizable: false,
            icon: "fa-solid fa-croissant",
            contains: "categories",
            description: "Categories",
            widgetConfig: {
                categories: []
            },
        },
        {
            x: 0,
            y: 0,
            w: 12,
            h: 1,
            i: uuidv4(),
            icon: "fas fa-puzzle-piece",
            contains: "prefab",
            isResizable: true,
            description: "Prefab",
            widgetConfig: {
                name: "",
                theme: "light",
            },
        },
        {
            x: 0,
            y: 0,
            w: 4,
            h: 1,
            i: uuidv4(),
            icon: "fa fa-sticky-note",
            contains: "raw-html",
            description: "Insert raw html code",
            widgetConfig: {
                content: `
                
                
                
                
                
                
                
                
                
                
                `,
                label: "",
            },
        },
    ]
