<template>
  <div class="form-group mt-2">
    <label :for="id" v-if="!hidelabel">
      {{ label }}
      <span class="inputlabel">{{input}}%</span>
    </label>
    <input :id="id" type="range" class="form-control slider" :value="input" :min="min" :max="max" :disabled="disabled" @input="input = $event.target.value" />
    <small class="form-text text-muted">{{ help }}</small>
  </div>
</template>

<script>
export default {
  props: [
    "value",
    "label",
    "options",
    "disabled",
    "help",
    "min",
    "max",
    "hidelabel",
  ],
  data() {
    return {
      input: Math.floor(this.value * this.max),
    };
  },
  computed: {
    id() {
      return "text_input_" + this._uid;
    },
  },
  watch: {
    input(input) {
      this.$emit("input", input / this.max);
    },
    value(value) {
      this.input = Math.floor(value * this.max);
    },
  },
};
</script>
<style lang="scss" scoped>
input {
  margin-top: 6px;
  &:focus {
    border-color: #dcdcdc;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
*:focus {
  outline: none;
}
.inputlabel {
  font-size: 0.8em;
  margin-left: 10px;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #ebebeb;
  border-radius: 16px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  padding: 4px;
}
.slider:hover {
  opacity: 1;
}
</style>