<template>
  <div class="h-100">
    <widget-template :id="id" name="Blog" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')">
      <div class="d-flex align-items-center">
        <div class="image" :style="'border-radius:10px;width:150px;height:100px;background-position:center;background-repeat: no-repeat;background-size: cover;background-image:url(' +
      latestMediaUrl +
      ')'
    "></div>
        <div class="ms-4 bold">{{ latestPostTitle }}</div>
      </div>
    </widget-template>

    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:svg>
          <div class="w-full h-full flex items-center justify-center">
            <i class="text-white relative right-1" :class="icon" style="font-size:40px;"></i>
          </div>
        </template>
        <template v-slot:title>
          <h5>Blog</h5>
        </template>
        <template v-slot:body>
          <group-input label="About 'Blog' Block" class="text-center">
            <p class="mt-4">
              <i class="fas fa-info-square"></i> shows the latest post from the blog along with a link to view all posts.
            </p>
          </group-input>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetConfigModal from "./WidgetConfigModal";
import axios from "./../../http.js";
import WidgetTemplate from "./WidgetTemplate.vue";

export default {
  components: {
    WidgetTemplate,
    WidgetConfigModal,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {
      latestMediaUrl: null,
      latestPostTitle: null,
      latestPostAbstract: null,
    };
  },
  methods: {
    config() {
      this.$refs["modal-" + this.id].show();
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
  mounted() {
    axios
      .get("/api/posts/latest")
      .then((response) => {
        this.$nextTick(() => {
          if (response.data.data) {
            this.latestMediaUrl = response.data.data.media_url;
            this.latestPostTitle = response.data.data.title;
          }
          this.ready = true;
        });
      })
      .finally(() => {});
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.form-group .row {
  margin-top: 10px;
}
.form-preview-image {
  width: 250px;
  margin: 10px 0px;
}
</style>