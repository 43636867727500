<template>
  <div>
    <div class="p-4 pt-2">
      <div class="row">
        <div class="col-auto">
          <select-input label="Type" style="max-width: 200px;" :options="[
          {value: 'text', label: 'Text'},
          {value: 'image', label: 'Image'},
        ]" v-model="layer.type" />
        </div>
      </div>

      <div v-if="layer.type === 'image'">
        <div class="row mt-4">
          <div class="col-12">
            <file-uploader v-model="layer.image.url" :key="'file-uploader--'+this._uid" label="Image" type="image" style="height:200px;" />
          </div>
          <div class="col-12 mt-4">
            <div class="row d-flex align-items-center">
              <div class="col-3">
                <select-input
                  label="Position"
                  style="max-width: 200px;"
                  class
                  :options="[
              {value: 'center', label: 'Center'},
              {value: 'top', label: 'Top'},
              {value: 'bottom', label: 'Bottom'},
              {value: 'left', label: 'Left'},
              {value: 'right', label: 'Right'},
            ]"
                  v-model="layer.image.position"
                  :disabled="
                  layer.image.position != '' &&
                  layer.image.position != 'center' &&
                  layer.image.position != 'top' &&
                  layer.image.position != 'bottom' &&
                  layer.image.position != 'left' &&
                  layer.image.position != 'right'"
                />
              </div>
              <div class="col-6">
                <text-input help="https://developer.mozilla.org/en-US/docs/Web/CSS/background-position" label="Advanced Position options" type="text" v-model="layer.image.position" />
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div class="col-3">
                <select-input
                  label="Size"
                  style="max-width: 200px;"
                  :options="[
                  {value: 'cover', label: 'Cover'},
                  {value: 'contain', label: 'Contain'},
                  {value: 'auto', label: 'Auto'},
                ]"
                  v-model="layer.image.size"
                  :disabled="layer.image.size != '' && layer.image.size != 'cover' && layer.image.size != 'contain' &&  layer.image.size != 'auto'"
                />
              </div>
              <div class="col-6">
                <text-input help="https://developer.mozilla.org/en-US/docs/Web/CSS/background-size" label="Advanced Size options" type="text" v-model="layer.image.size" />
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div class="col-3">
                <select-input
                  label="Repeat"
                  style="max-width: 200px;"
                  :options="[
                  {value: 'no-repeat', label: 'No-Repeat'},
                  {value: 'repeat', label: 'Repeat'},
                  {value: 'repeat-x', label: 'Repeat-X'},
                  {value: 'space', label: 'Space'},
                ]"
                  v-model="layer.image.repeat"
                  :disabled="
              layer.image.repeat != '' &&
              layer.image.repeat != 'no-repeat' &&
              layer.image.repeat != 'repeat' &&
              layer.image.repeat != 'repeat-x' &&
              layer.image.repeat != 'space'"
                />
              </div>
              <div class="col-6">
                <text-input help="https://developer.mozilla.org/en-US/docs/Web/CSS/background-repeat" label="Advanced Repeat options" type="text" v-model="layer.image.repeat" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <select-input
                  label="Blend Mode"
                  style="max-width: 200px;"
                  :options="[
                    {value: 'normal', label: 'Normal'},
                    {value: 'multiply', label: 'Multiply'},
                    {value: 'screen', label: 'Screen'},
                    {value: 'overlay', label: 'Overlay'},
                    {value: 'darken', label: 'Darken'},
                    {value: 'lighten', label: 'Lighten'},
                    {value: 'color-dodge', label: 'Color Dodge'},
                    {value: 'color-burn', label: 'Color Burn'},
                    {value: 'hard-light', label: 'Hard Light'},
                    {value: 'soft-light', label: 'Soft Light'},
                    {value: 'difference', label: 'Difference'},
                    {value: 'exclusion', label: 'Exclusion'},
                    {value: 'hue', label: 'Hue'},
                    {value: 'saturation', label: 'Saturation'},
                    {value: 'color', label: 'Color'},
                    {value: 'luminosity', label: 'Luminosity'},
                  ]"
                  v-model="layer.image.blendMode"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <text-input label="Padding" v-model="layer.image.padding" />
              </div>
              <div class="col-6">
                <text-input label="Margin" v-model="layer.image.margin" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="layer.type === 'text'">
        <div class="row">
          <div class="col-6">
            <text-input label="Text" v-model="layer.text.text" />
          </div>
          <div class="col-6">
            <select-input
              label="Level"
              :options="[
              {value: 'h1', label: 'H1'},
              {value: 'h2', label: 'H2'},
              {value: 'h3', label: 'H3'},
              {value: 'h4', label: 'H4'},
              {value: 'h5', label: 'H5'},
              {value: 'h6', label: 'H6'},
              {value: 'div', label: 'Paragraph'},
            ]"
              v-model="layer.text.level"
            />
          </div>
          <div class="col-6">
            <text-input label="Mobile Font Size" v-model="layer.text.mobileFontSize" />
          </div>
          <div class="col-6">
            <text-input label="Desktop Font Size" v-model="layer.text.desktopFontSize" />
          </div>
          <div class="col-6">
            <text-input label="Font Weight" help="https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight" v-model="layer.text.fontWeight" />
          </div>
          <div class="col-6">
            <text-input label="Letter Spacing" v-model="layer.text.letterSpacing" />
          </div>
          <div class="col-6">
            <text-input label="Border Radius" v-model="layer.text.borderRadius" />
          </div>
          <div class="col-6">
            <text-input label="Line Height" v-model="layer.text.lineHeight" />
          </div>
          <div class="col-6">
            <align-selector label="Alignment" v-model="layer.text.textAlign" />
          </div>
          <div class="col-6">
            <select-input
              label="Blend Mode"
              :options="[
                          {value: 'normal', label: 'Normal'},
                          {value: 'multiply', label: 'Multiply'},
                          {value: 'screen', label: 'Screen'},
                          {value: 'overlay', label: 'Overlay'},
                          {value: 'darken', label: 'Darken'},
                          {value: 'lighten', label: 'Lighten'},
                          {value: 'color-dodge', label: 'Color Dodge'},
                          {value: 'color-burn', label: 'Color Burn'},
                          {value: 'hard-light', label: 'Hard Light'},
                          {value: 'soft-light', label: 'Soft Light'},
                          {value: 'difference', label: 'Difference'},
                          {value: 'exclusion', label: 'Exclusion'},
                          {value: 'hue', label: 'Hue'},
                          {value: 'saturation', label: 'Saturation'},
                          {value: 'color', label: 'Color'},
                          {value: 'luminosity', label: 'Luminosity'},
                        ]"
              v-model="layer.text.blendMode"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <text-input label="Padding" v-model="layer.text.padding" />
          </div>
          <div class="col-6">
            <text-input label="Margin" v-model="layer.text.margin" />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>Text Colour</label>
            <colour-picker v-model="layer.text.color" />
          </div>
          <div class="col-6">
            <label>Background Colour</label>
            <colour-picker v-model="layer.text.background" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColourPicker from "./colour-picker.vue";
import FileUploader from "./../helpers/FileUploader.vue";
import SelectInput from "./select-input.vue";
import TextInput from "./text-input.vue";

export default {
  props: ["value", "uuid"],
  components: {
    ColourPicker,
    FileUploader,
    SelectInput,
    TextInput,
  },
  data() {
    return {
      layer: this.value,
    };
  },
  watch: {
    layer(value) {
      this.$emit("input", value);
    },
    value(value) {
      this.layer = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.question-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    fill: #cacaca;
  }
  &:hover {
    svg {
      fill: #888;
    }
  }
}
.mask {
  color: #cacaca;
  fill: #cacaca;
  .active {
    color: #888;
    fill: #888;
  }
  .mask-title {
    font-weight: bold;
  }
  border-top: 1px solid #ccc;
  .content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    &.expanded {
      max-height: 1000px;
    }
  }
}
</style>