<template>
  <button class="widget_remove_btn" @click.prevent="click($event)">
    <i class="fa fa-trash" :style="'color:' + colour"></i>
  </button>
</template>
<script>
export default {
  props: {
    colour: {
      default: "#FFFFFF",
    },
  },
  data() {
    return {};
  },
  methods: {
    click(event) {
      this.$emit("click", event);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.widget {
  .widget_remove_btn {
    position: absolute;
    top: 4px;
    right: 50px;
    background: none;
    border: none;
    color: white;
    font-size: 80%;
    opacity: 1;
    pointer-events: none;
    outline: none !important;
    transition: all 250ms ease-in-out;
    font-size: 1.3em;
  }

  &:hover {
    .widget_remove_btn {
      opacity: 1;
      pointer-events: unset;
    }
  }
}
</style>