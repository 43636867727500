<template>
  <div>
    <div class="p-4 pt-2">
      <div class="row">
        <div class="col-auto">
          <select-input
            label="Type"
            style="max-width: 200px;"
            :options="[
          {value: 'colour', label: 'Colour'},
          {value: 'gradient', label: 'Gradient'},
          {value: 'image', label: 'Image'},
          {value: 'video', label: 'Video'},
          {value: 'blur', label: 'Blur'},
        ]"
            v-model="layer.type"
          />
        </div>
        <div class="col-auto">
          <select-input
            label="Blend Mode"
            style="max-width: 200px;"
            :options="[
            {value: 'normal', label: 'Normal'},
            {value: 'multiply', label: 'Multiply'},
            {value: 'screen', label: 'Screen'},
            {value: 'overlay', label: 'Overlay'},
            {value: 'darken', label: 'Darken'},
            {value: 'lighten', label: 'Lighten'},
            {value: 'color-dodge', label: 'Color Dodge'},
            {value: 'color-burn', label: 'Color Burn'},
            {value: 'hard-light', label: 'Hard Light'},
            {value: 'soft-light', label: 'Soft Light'},
            {value: 'difference', label: 'Difference'},
            {value: 'exclusion', label: 'Exclusion'},
            {value: 'hue', label: 'Hue'},
            {value: 'saturation', label: 'Saturation'},
            {value: 'color', label: 'Color'},
            {value: 'luminosity', label: 'Luminosity'},
          ]"
            v-model="layer.blendMode"
          />
        </div>
        <div class="col-auto">
          <slider-input label="Opacity" v-model="layer.opacity" :min="0" :max="100" />
        </div>
      </div>

      <div v-if="layer.type === 'colour'">
        <div class="row mt-4">
          <div class="col">
            <colour-picker :key="'color-picker-'+this._uid" v-model="layer.color" />
          </div>
        </div>
      </div>
      <div v-if="layer.type === 'gradient'" class="d-flex">
        <div class="row mt-4">
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <colour-picker :key="'picker-gradient-from-'+this._uid" v-model="layer.gradient.from" />
              </div>
              <div class="col-auto">
                <colour-picker :key="'picker-gradient-to-'+this._uid" v-model="layer.gradient.to" />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col-12">
                <text-input label="From (percentage)" v-model="layer.gradient.fromPercentage" />
              </div>
              <div class="col-12">
                <text-input label="To (percentage)" v-model="layer.gradient.toPercentage" />
              </div>
              <div class="col-12">
                <text-input label="Angle" v-model="layer.gradient.angle" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="layer.type === 'image'">
        <div class="row mt-4">
          <div class="col-12">
            <file-uploader v-model="layer.image.url" :key="'file-uploader--'+this._uid" label="Image" type="image" style="height:200px;" />
          </div>
          <div class="col-12 mt-4">
            <div class="row d-flex align-items-center">
              <div class="col-3">
                <select-input
                  label="Position"
                  style="max-width: 200px;"
                  class
                  :options="[
              {value: 'center', label: 'Center'},
              {value: 'top', label: 'Top'},
              {value: 'bottom', label: 'Bottom'},
              {value: 'left', label: 'Left'},
              {value: 'right', label: 'Right'},
            ]"
                  v-model="layer.image.position"
                  :disabled="
                  layer.image.position != '' &&
                  layer.image.position != 'center' &&
                  layer.image.position != 'top' &&
                  layer.image.position != 'bottom' &&
                  layer.image.position != 'left' &&
                  layer.image.position != 'right'"
                />
              </div>
              <div class="col-6">
                <text-input help="https://developer.mozilla.org/en-US/docs/Web/CSS/background-position" label="Advanced Position options" type="text" v-model="layer.image.position" />
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div class="col-3">
                <select-input
                  label="Size"
                  style="max-width: 200px;"
                  :options="[
                  {value: 'cover', label: 'Cover'},
                  {value: 'contain', label: 'Contain'},
                  {value: 'auto', label: 'Auto'},
                ]"
                  v-model="layer.image.size"
                  :disabled="layer.image.size != '' && layer.image.size != 'cover' && layer.image.size != 'contain' &&  layer.image.size != 'auto'"
                />
              </div>
              <div class="col-6">
                <text-input help="https://developer.mozilla.org/en-US/docs/Web/CSS/background-size" label="Advanced Size options" type="text" v-model="layer.image.size" />
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div class="col-3">
                <select-input
                  label="Repeat"
                  style="max-width: 200px;"
                  :options="[
                  {value: 'no-repeat', label: 'No-Repeat'},
                  {value: 'repeat', label: 'Repeat'},
                  {value: 'repeat-x', label: 'Repeat-X'},
                  {value: 'space', label: 'Space'},
                ]"
                  v-model="layer.image.repeat"
                  :disabled="
              layer.image.repeat != '' &&
              layer.image.repeat != 'no-repeat' &&
              layer.image.repeat != 'repeat' &&
              layer.image.repeat != 'repeat-x' &&
              layer.image.repeat != 'space'"
                />
              </div>
              <div class="col-6">
                <text-input help="https://developer.mozilla.org/en-US/docs/Web/CSS/background-repeat" label="Advanced Repeat options" type="text" v-model="layer.image.repeat" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="layer.type === 'video'">
        <file-uploader :key="'file-uploader-video-'+this._uid" v-model="layer.video.url" label="Video" type="video" style="height:200px;" />
        <div class="row">
          <div class="col-6">
            <text-input label="Offset Time (seconds)" v-model="layer.video.offset" />
          </div>
        </div>
      </div>
      <div v-if="layer.type === 'blur'">
        <div class="row mt-4">
          <div class="col-auto">
            <text-input label="Blur (pixels)" v-model="layer.blur" />
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-if="layer.parallax">
      <div class="title d-flex justify-content-between align-items-center p-3">
        <div :class="{'active': layer.parallax.active}">
          <i class="fa-solid fa-rhombus"></i>
          <span class="mask-title ps-2">PARALLAX</span>
        </div>
        <checkbox-input style="margin-top:-4px !important;" v-model="layer.parallax.active" />
      </div>
      <div class="mask-content" v-if="layer.parallax.active" :ref="'chevron_mask_content_'+uuid+layer.id">
        <div class="row px-4 pb-4">
          <div class="col-12">Note: parallax effect doesn't work with blend mode or opacity</div>
          <div class="col-6">
            <text-input label="Parallax depth" placeholder="-500px" v-if="layer.parallax && layer.parallax.active === true" v-model="layer.parallax.translateZ" />
          </div>
          <div class="col-6">
            <text-input label="Parallax scale" placeholder="1.3" v-if="layer.parallax && layer.parallax.active === true" v-model="layer.parallax.scale" />
          </div>
        </div>
      </div>
    </div>
    <div class="mask" v-if="layer.mask">
      <div class="title d-flex justify-content-between align-items-center p-3">
        <div :class="{'active': layer.mask.active}">
          <i class="fa-solid fa-mask mask-icon"></i>
          <span class="mask-title ps-2">MASK</span>
        </div>
        <checkbox-input style="margin-top:-4px !important;" v-model="layer.mask.active" />
      </div>
      <div class="mask-content" v-if="layer.mask.active" :ref="'chevron_mask_content_'+uuid+layer.id">
        <div class="row px-4 pb-4">
          <div class="col-12">
            <file-uploader v-model="layer.mask.url" :key="'file-uploader--'+this._uid" label="Image" type="image" style="height:200px;" />
          </div>
          <div class="col-12 mt-4">
            <div class="row d-flex align-items-center">
              <div class="col-3">
                <select-input
                  label="Position"
                  style="max-width: 200px;"
                  class
                  :options="[
              {value: 'center', label: 'Center'},
              {value: 'top', label: 'Top'},
              {value: 'bottom', label: 'Bottom'},
              {value: 'left', label: 'Left'},
              {value: 'right', label: 'Right'},
            ]"
                  v-model="layer.mask.position"
                  :disabled="
                  layer.mask.position != '' &&
                  layer.mask.position != 'center' &&
                  layer.mask.position != 'top' &&
                  layer.mask.position != 'bottom' &&
                  layer.mask.position != 'left' &&
                  layer.mask.position != 'right'"
                />
              </div>
              <div class="col-6">
                <text-input help="https://developer.mozilla.org/en-US/docs/Web/CSS/background-position" label="Advanced Position options" type="text" v-model="layer.mask.position" />
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div class="col-3">
                <select-input
                  label="Size"
                  style="max-width: 200px;"
                  :options="[
                  {value: 'cover', label: 'Cover'},
                  {value: 'contain', label: 'Contain'},
                  {value: 'auto', label: 'Auto'},
                ]"
                  v-model="layer.mask.size"
                  :disabled="layer.mask.size != '' && layer.mask.size != 'cover' && layer.mask.size != 'contain' &&  layer.mask.size != 'auto'"
                />
              </div>
              <div class="col-6">
                <text-input help="https://developer.mozilla.org/en-US/docs/Web/CSS/background-size" label="Advanced Size options" type="text" v-model="layer.mask.size" />
              </div>
            </div>
            <div class="row d-flex align-items-center">
              <div class="col-3">
                <select-input
                  label="Repeat"
                  style="max-width: 200px;"
                  :options="[
                  {value: 'no-repeat', label: 'No-Repeat'},
                  {value: 'repeat', label: 'Repeat'},
                  {value: 'repeat-x', label: 'Repeat-X'},
                  {value: 'space', label: 'Space'},
                ]"
                  v-model="layer.mask.repeat"
                  :disabled="
              layer.mask.repeat != '' &&
              layer.mask.repeat != 'no-repeat' &&
              layer.mask.repeat != 'repeat' &&
              layer.mask.repeat != 'repeat-x' &&
              layer.mask.repeat != 'space'"
                />
              </div>
              <div class="col-6">
                <text-input help="https://developer.mozilla.org/en-US/docs/Web/CSS/background-repeat" label="Advanced Repeat options" type="text" v-model="layer.mask.repeat" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColourPicker from "./colour-picker.vue";
import FileUploader from "./../helpers/FileUploader.vue";
import SelectInput from "./select-input.vue";
import CheckboxInput from "./checkbox-input.vue";
import SliderInput from "./slider-input.vue";
import TextInput from "./text-input.vue";

export default {
  props: ["value", "uuid"],
  components: {
    ColourPicker,
    FileUploader,
    SelectInput,
    SliderInput,
    TextInput,
    CheckboxInput,
  },
  data() {
    return {
      layer: this.value,
    };
  },
  watch: {
    layer(value) {
      this.$emit("input", value);
    },
    value(value) {
      this.layer = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.question-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    fill: #cacaca;
  }
  &:hover {
    svg {
      fill: #888;
    }
  }
}
.mask {
  .title {
    color: #cacaca;
    fill: #cacaca;
    .active {
      color: #888;
      fill: #888;
    }
  }
  .mask-title {
    font-weight: bold;
  }
  border-top: 1px solid #ccc;
  .mask-content {
    overflow: scroll;
  }
}
</style>