
<template>
  <div class="h-100">
    <widget-template :id="id" name="Spacer" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')"></widget-template>

    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:title>Image</template>
        <template v-slot:subtitle>{{ widget.label }}</template>
        <template v-slot:body>
          <group-input label="Settings">
            <div class="row">
              <div class="col-2">
                <select-input label="Spacer Height" v-model="widget.height" :options="heightOptions" />
              </div>
            </div>
          </group-input>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetConfigModal from "./WidgetConfigModal";
import WidgetTemplate from "./WidgetTemplate.vue";

export default {
  components: {
    WidgetConfigModal,
    WidgetTemplate,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {
      categories: {},
      heightOptions: [
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
      ],
    };
  },
  methods: {
    config() {
      this.$refs["modal-" + this.id].show();
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.form-group .row {
  margin-top: 10px;
}
.form-preview-image {
  width: 250px;
  margin: 10px 0px;
}
.banner-content {
  color: white;
  background: black;
}
</style>