<template>
  <div class="h-100">
    <widget-template :id="id" name="Custom Banner" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')">
      <span class="widget-text-content">
        <div v-if="textElements.length > 0">{{ textElements[0].config.text.text ?? '' }}</div>
        <div v-if="textElements.length > 1">...</div>
      </span>

      <br />
      <div class="fakebuton" v-if="widget.cta.hasButton && widget.cta.button && widget.cta.button.text">
        {{
        this.widget.cta.button.text
        }}
      </div>
    </widget-template>
    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id" templateType="custom-banner" :currentWidget="widget" @chooseTemplate="chooseTemplate">
        <template v-slot:svg>
          <div class="w-full h-full flex items-center justify-center">
            <i class="text-white" :class="icon" style="font-size: 40px"></i>
          </div>
        </template>
        <template v-slot:title>Customisable Banner</template>
        <template v-slot:body>
          <group-input label="Layout">
            <div class="row">
              <div class="col-12">
                <checkbox-input label="Debug Mode" v-model="widget.debug" />
                <p class="text-muted mt-2">
                  <i class="far fa-lightbulb"></i> This option will show outlines of all the elements inside the banner, and it's only visible in the Live Preview
                </p>
              </div>
              <div class="col-6">
                <checkbox-input label="Edge to Edge" v-model="widget.fullWidth" />
                <p class="text-muted mt-2">
                  <i class="far fa-lightbulb"></i> This option will determine if the banner will take the full width of the screen
                </p>
              </div>
              <div class="col-6">
                <checkbox-input label="Put content in a container" v-model="widget.layout.requiresContainer" />
              </div>
              <div class="col-6">
                <select-input
                  label="Content Container Width"
                  v-model="widget.layout.width"
                  help="https://getbootstrap.com/docs/5.0/layout/grid/"
                  :options="[
                    {value: 'fill', label: 'Fill'},
                    {value: 'auto', label: 'Auto'},
                    {value: '1', label: '1'},
                    {value: '2', label: '2'},
                    {value: '3', label: '3'},
                    {value: '4', label: '4'},
                    {value: '5', label: '5'},
                    {value: '6', label: '6'},
                    {value: '7', label: '7'},
                    {value: '8', label: '8'},
                    {value: '9', label: '9'},
                    {value: '10', label: '10'},
                    {value: '11', label: '11'},
                    {value: '12', label: '12'},
                  ]"
                />
              </div>
              <div class="col-6">
                <align-selector label="Container Alignment" v-model="widget.layout.containerAlign" />
              </div>
              <div class="col-6">
                <text-input label="Container Min Height" help="https://developer.mozilla.org/en-US/docs/Web/CSS/height" v-model="widget.layout.height" />
              </div>
              <div class="col-6">
                <text-input label="Container Min Height Mobile" v-model="widget.layout.mobileHeight" />
              </div>
              <div class="col-6 mt-2">
                <label class>Vertical Alignment</label>
                <select class="form-select" v-model="widget.layout.verticalAlign">
                  <option value="start">Top</option>
                  <option value="center">Center</option>
                  <option value="end">Bottom</option>
                </select>
              </div>
              <div class="col-6">
                <text-input label="Padding" v-model="widget.layout.padding" help="https://developer.mozilla.org/en-US/docs/Web/CSS/padding" />
              </div>
            </div>
          </group-input>

          <div class="row">
            <div class="col-6">
              <group-input label="Background">
                <layers-manager :isdraggable="true" type="background" v-model="widget.background" key="layers_manager_desktop" ref="layers_manager_desktop" :uuid="id" @copyLayerToTarget="copyLayerToTarget($event)" />
              </group-input>
            </div>
            <div class="col-6">
              <group-input label="Mobile Background">
                <layers-manager v-model="widget.mobile_background" type="background" key="layers_manager_mobile" ref="layers_manager_mobile" :uuid="id" @copyLayerToTarget="copyLayerToTarget($event)" />
              </group-input>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <group-input label="Content">
                <layers-manager :isdraggable="true" type="content" v-model="widget.content" key="content_manager" ref="content_manager_desktop" :uuid="id" />
              </group-input>
            </div>
            <div class="col-6">
              <group-input label="Call to Action">
                <label class>Link Type</label>
                <select class="form-select" v-model="widget.cta.action.name">
                  <option value="category">Category</option>
                  <option value="page">Page</option>
                  <option value="link">Custom Address</option>
                </select>
                <text-input class="mt-2" label="Link" v-if="widget.cta.action.name=='link'" v-model="widget.cta.action.params.link" />
                <input type="hidden" v-if="widget.cta.action.name=='category'" v-model="widget.cta.action.name" />
                <categories-select v-if="widget.cta.action.name=='category'" class="mt-2" v-model="widget.cta.action.params.category" label="Action params category" />
                <pages-select v-if="widget.cta.action.name=='page'" class="mt-2" v-model="widget.cta.action.params.page" label="Action page" />
                <checkbox-input class="mt-2" label="Open in a new tab" v-model="widget.cta.action.params.targetBlank" />
                <checkbox-input class="mt-2" label="Whole banner clickable" v-model="widget.cta.wholeBannerClickable" />
                <checkbox-input class="mt-2" label="Has button" v-model="widget.cta.hasButton" />
                <text-input label="Text" v-if="widget.cta.hasButton" v-model="widget.cta.button.text" />
                <div class="row" v-if="widget.cta.hasButton">
                  <div class="col-6">
                    <cta-selector label="Button Type" v-if="widget.cta.hasButton" v-model="widget.cta.button.type" />
                  </div>
                  <div class="col-6">
                    <align-selector label="Alignment" v-model="widget.cta.button.style.align" />
                  </div>
                </div>
                <div class v-if="widget.cta.hasButton && widget.cta.button.type == 'custom'">
                  <div class="row mt-3">
                    <div class="col-6">
                      <label>Background</label>
                      <colour-picker v-model="widget.cta.button.style.bg" />
                    </div>
                    <div class="col-6">
                      <label>Background Hover</label>
                      <colour-picker v-model="widget.cta.button.style.bg_hover" />
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-6">
                      <label>Text</label>
                      <colour-picker v-model="widget.cta.button.style.text" />
                    </div>
                    <div class="col-6">
                      <label>Text Hover</label>
                      <colour-picker v-model="widget.cta.button.style.text_hover" />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-6">
                      <label>Border</label>
                      <colour-picker v-model="widget.cta.button.style.border" />
                    </div>
                    <div class="col-6">
                      <label>Border Hover</label>
                      <colour-picker v-model="widget.cta.button.style.border_hover" />
                    </div>
                  </div>
                </div>
              </group-input>
            </div>
          </div>
          <input type="hidden" name="action-params-react" v-model="widget.cta.action.params.react" value="yes" />
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetConfigModal from "./WidgetConfigModal";
import CategoriesSelect from "./helpers/Categories";
import PagesSelect from "./helpers/Pages";
import CheckboxInput from "./inputs/checkbox-input.vue";
import SelectInput from "./inputs/select-input.vue";
import LayersManager from "./helpers/LayersManager.vue";
import ColourPicker from "./inputs/colour-picker.vue";
import WidgetTemplate from "./WidgetTemplate.vue";

export default {
  components: {
    WidgetTemplate,
    WidgetConfigModal,
    CategoriesSelect,
    PagesSelect,
    CheckboxInput,
    SelectInput,
    LayersManager,
    ColourPicker,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {
      headingExpanded: false,
      subheadingExpanded: false,
      contentExpanded: false,
      categories: {},
    };
  },
  computed: {
    textElements() {
      let elements = this.widget.content.filter((element) => {
        return element.config.type == "text";
      });
      return elements.length >= 1 ? elements : [];
    },
  },
  methods: {
    chooseTemplate(template) {
      this.$emit("loadedTemplate", template);
    },
    copyLayerToTarget(event) {
      // Copy a layer from desktop to mobile and vice versa
      console.log("copying to ", event);
      this.$refs[event.target].addLayer(null, event.layer);
    },
    aspectsHide() {
      this.$refs["aspect-show-btn_" + this.id].toggle();
      this.$refs["color-picker_" + this.id].classList.remove("active");
      this.$emit("toggleDraggable", true);
    },
    aspectShow() {
      // toggle class on button
      this.$refs["aspect-show-btn_" + this.id].toggle();

      let picker = this.$refs["color-picker_" + this.id];
      // this widget is visible, but the parent is capturing the drag event
      // so we need to pause the draggable
      if (picker.classList.contains("active")) {
        picker.classList.remove("active");
        this.$emit("toggleDraggable", true);
      } else {
        picker.classList.add("active");
        this.$emit("toggleDraggable", false);
      }
    },
    deleteSelf() {
      if (confirm("Do you really want to delete?")) {
        this.$refs["modal-" + this.id].hide();
        this.$emit("delete");
      }
    },
    config() {
      this.$refs["modal-" + this.id].show();
    },
    duplicate() {
      if (confirm("duplicate item?")) {
        this.$emit("duplicate");
      }
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
  .widget-label {
    display: inline-block;
  }
}
.save {
  float: right;
}
.form-group .row {
  margin-top: 10px;
}
.form-preview-image {
  width: 250px;
  margin: 10px 0px;
}
.collapsible {
  transition: 0.4s;
  max-height: 0;
  overflow: hidden;
  &.expanded {
    max-height: 1000px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}
.collapse-icon {
  cursor: pointer;
  width: 30px;
  svg {
    path {
      fill: #2c3e50;
    }
  }
}
.aspects-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  transition: 0.4s;

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  .aspects {
    background: white;
    border: 1px solid #ccc;
    position: absolute;
    top: 20px;
    right: 140px;
    pointer-events: unset;
    z-index: 2;
    .vc-chrome {
      display: flex;
    }
  }
}
</style>