<template>
  <div class="h-100">
    <widget-template :id="id" name="Categories" :icon="icon" :widget="widget" @config="config()" @toggleDraggable="$emit('toggleDraggable', $event)" @duplicate="$emit('duplicate')" @delete="$emit('delete')" @updateLabel="$emit('updateLabel')">
      <div class="d-flex align-items-center" style="height:130px;">
        <div class="m-1 p-2 w-100 d-flex justify-content-evenly align-items-center">
          <div v-for="(category,k) in widget.categories" :key="k" class="category-item" :style="'background-image:url('+category.background+');'"></div>
        </div>
      </div>
    </widget-template>

    <portal to="widget-modals">
      <widget-config-modal :ref="'modal-' + id">
        <template v-slot:title>Image</template>
        <template v-slot:subtitle>{{ widget.label }}</template>
        <template v-slot:body>
          <group-input label="About 'Categories' Block" class="text-center">
            <p class="mt-4">
              <i class="fas fa-info-square"></i> List of categories with background image and CTA.
            </p>
          </group-input>

          <group-input label="Meta &amp; Presentation">
            <div class="row">
              <div class="col">
                <text-input v-model="widget.label" label="Label" />
                <p class="text-muted mt-2">
                  <i class="far fa-lightbulb"></i>Label is used for reference only and never shown on the front end.
                </p>
              </div>
            </div>
          </group-input>

          <group-input label="Categories">
            <div class="row">
              <div class="col">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>CTA</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(category,k) in widget.categories" :key="k" style="height: 50px;">
                      <td class="px-4">
                        <img :src="category.background" style="max-width: 130px;" />
                      </td>
                      <td>
                        <div style="color:white;text-align:center;" :style="'background: ' + getColor(category.buttonType)">{{category.cta}}</div>
                      </td>
                      <td>{{category.ctaAction}}</td>
                      <td>
                        <a target="_blank" :href="pwaUrl + '/products/categories/' + category.label">{{category.label}}</a>
                      </td>
                      <td>
                        <button class="btn btn-sm btn-secondary me-1" @click="moveBrandUp(k)" :disabled="k == 0">
                          <i class="fa-solid fa-arrow-up"></i>
                        </button>
                        <button class="btn btn-sm btn-secondary me-1" @click="moveBrandDown(k)" :disabled="k == (Object.keys(widget.categories).length - 1)">
                          <i class="fa-solid fa-arrow-down"></i>
                        </button>
                      </td>
                      <td>
                        <button class="btn btn-sm btn-danger" @click="removeCategory(k)">
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </group-input>

          <group-input label="Add Category">
            <div class="row d-flex align-items-end">
              <div class="col-4">
                <div class="form-group">
                  <file-uploader v-model="backgroundUrl" label="New Logo" style="width:100%;border:1px solid lightgray;" />
                </div>
              </div>
              <div class="col-4">
                <div class="form-group">
                  <text-input v-model="cta" label="CTA" />
                </div>
              </div>

              <categories-select class="mt-2" v-model="selectedCategory" label="Action params category" />

              <div class="form-group button-type-group d-flex flex-column mt-2">
                <label>Button Color</label>
                <div class="d-flex">
                  <div class="me-2">
                    <input type="radio" id="primary" value="primary" v-model="buttonType" />
                    <label class="label" for="primary" style="background:#feb103;"></label>
                  </div>
                  <div class="me-2">
                    <input type="radio" id="secondary" value="secondary" v-model="buttonType" />
                    <label class="label" for="secondary" style="background:#029F4B;"></label>
                  </div>
                  <div class="me-2">
                    <input type="radio" id="tertiary" value="tertiary" v-model="buttonType" />
                    <label class="label" for="tertiary" style="background:#181818;"></label>
                  </div>
                  <div class="me-2">
                    <input type="radio" id="yellow" value="yellow" v-model="buttonType" />
                    <label class="label" for="yellow" style="background:#F7C401;"></label>
                  </div>
                  <div class="me-2">
                    <input type="radio" id="lightblue" value="lightblue" v-model="buttonType" />
                    <label class="label" for="lightblue" style="background:#5FC8BF;"></label>
                  </div>
                  <div class="me-2">
                    <input type="radio" id="blue" value="blue" v-model="buttonType" />
                    <label class="label" for="blue" style="background:#518BD6;"></label>
                  </div>
                </div>
              </div>

              <div class="col-4 mt-2">
                <div class="form-group">
                  <button class="vave-btn btn-green" @click="addCategory" :disabled="cta == null || backgroundUrl == null || selectedCategory == null">Add</button>
                </div>
              </div>
            </div>
          </group-input>
        </template>
        <template v-slot:footer>
          <button class="vave-btn btn-blue save" @click="save">Close</button>
        </template>
      </widget-config-modal>
    </portal>
  </div>
</template>
<script>
import WidgetConfigModal from "./WidgetConfigModal";
import FileUploader from "./helpers/FileUploader";
import CategoriesSelect from "./helpers/Categories";
import WidgetTemplate from "./WidgetTemplate.vue";

export default {
  components: {
    WidgetTemplate,
    WidgetConfigModal,
    FileUploader,
    CategoriesSelect,
  },
  props: ["widget", "icon", "id"],
  data() {
    return {
      pwaUrl: process.env.VUE_APP_PWAURL,
      backgroundUrl: null,
      selectedCategory: null,
      buttonType: "primary",
      cta: null,
    };
  },
  methods: {
    getColor(buttonType) {
      switch (buttonType) {
        case "primary":
          return "#feb103";
        case "secondary":
          return "#029F4B";
        case "tertiary":
          return "#181818";
        case "yellow":
          return "#F7C401";
        case "lightblue":
          return "#5FC8BF";
        case "blue":
          return "#518BD6";
        default:
          return "#feb103";
      }
    },
    addCategory() {
      this.widget.categories.push({
        cta: this.cta,
        ctaAction: "/products/categories/" + this.selectedCategory,
        buttonType: this.buttonType,
        background: this.backgroundUrl,
      });
      this.cta = null;
      this.backgroundUrl = null;
      this.buttonType = "primary";
    },
    moveBrandDown(k) {
      if (k < Object.keys(this.widget.categories).length - 1) {
        var temp = this.widget.categories[k];
        this.widget.categories.splice(k, 1);
        this.widget.categories.splice(k + 1, 0, temp);
      }
    },
    moveBrandUp(k) {
      if (k > 0) {
        var temp = this.widget.categories[k];
        this.widget.categories.splice(k, 1);
        this.widget.categories.splice(k - 1, 0, temp);
      }
    },
    removeCategory(k) {
      if (confirm("are you sure?")) {
        this.widget.categories.splice(k, 1);
      }
    },
    config() {
      this.$refs["modal-" + this.id].show();
    },
    save() {
      this.$emit("widgetConfigUpdated");
      this.$refs["modal-" + this.id].hide();
    },
  },
};
</script>

<style lang="scss">
.header {
  background: #2c3e50;
  color: white;
  text-align: center;

  strong {
    font-family: "Ubuntu Mono", monospace;
    font-weight: bold;
  }
}
.save {
  float: right;
}
.form-group .row {
  margin-top: 10px;
}
.category-item {
  height: 130px;
  width: 130px;
  background-position: center center;
  background-size: cover;
}
.button-type-group {
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  input:active + .label {
    opacity: 0.9;
  }
  input:checked + .label {
    -webkit-filter: none;
    -moz-filter: none;
    filter: none;
    border: 2px solid black;
  }
  .label {
    cursor: pointer;
    width: 50px;
    height: 50px;
    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    -webkit-filter: brightness(1) grayscale(0) opacity(0.4);
    -moz-filter: brightness(1) grayscale(0) opacity(0.4);
    filter: brightness(1) grayscale(0) opacity(0.4);
    border: 0px;
  }
  .label:hover {
    -webkit-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
    -moz-filter: brightness(1.2) grayscale(0.5) opacity(0.9);
    filter: brightness(1.2) grayscale(0.5) opacity(0.9);
    border: 2px solid black;
  }
}
</style>