<template>
  <div class="widget" :style="'background: rgba(' + widget.color + ')'">
    <div class="header">
      <div class="d-flex justify-content-between flex-wrap align-items-center">
        <div class="d-flex align-items-center">
          <div class="me-2 d-flex justify-content-start align-items-center" style>
            <i :class="icon"></i>
            <div class="ps-2">{{ name }}</div>
          </div>
          <div class="d-flex justify-content-start">
            <div class="ms-2" style="font-size: 0.95em">
              <div class="widget-label" contenteditable="true" @input="updateLabel" :ref="'widget-label_'+id">...</div>
            </div>
          </div>
        </div>
        <div>
          <div class="icon">
            <button class="widget_btn" @click.prevent="$emit('config')">
              <i class="fa fa-cogs"></i>
            </button>
            <div class="btn_label">Open Configuration</div>
          </div>
          <div class="icon">
            <span v-show="widgetActive">
              <button class="widget_btn" @click.prevent="toggleActive()">
                <i class="fa fa-eye"></i>
              </button>
              <div class="btn_label">Disable widget</div>
            </span>
            <span v-show="!widgetActive" style="position: relative;left:-2px;">
              <button class="widget_btn red" @click.prevent="toggleActive()">
                <i class="fa fa-eye-slash"></i>
              </button>
              <div class="btn_label">Enable widget</div>
            </span>
          </div>
          <div class="icon">
            <span v-show="showOnMobile">
              <button class="widget_btn" @click.prevent="toggleMobile()">
                <i class="fa fa-mobile"></i>
              </button>
              <div class="btn_label">Hide on mobile</div>
            </span>
            <span v-show="!showOnMobile" style="position: relative;left:-2px;bottom:3px;">
              <button class="widget_btn red" @click.prevent="toggleMobile()">
                <i class="fa fa-mobile" style="position:absolute;top:0;left:0;"></i>
                <i class="fa fa-slash" style="position:absolute;top:0;left:-5px;"></i>
              </button>
              <div class="btn_label">Show on mobile</div>
            </span>
          </div>

          <div class="icon">
            <span v-show="showOnDesktop">
              <button class="widget_btn" @click.prevent="toggleDesktop()">
                <i class="fa fa-display"></i>
              </button>
              <span class="btn_label">Hide on desktop</span>
            </span>
            <span v-show="!showOnDesktop" style="position: relative;left:-2px;">
              <button class="widget_btn red" @click.prevent="toggleDesktop()">
                <i class="fa fa-display-slash"></i>
              </button>
              <span class="btn_label">Show on desktop</span>
            </span>
          </div>

          <div class="icon" style="position:relative;">
            <button :ref="'aspect-show-btn_'+id" class="widget_btn" @click.prevent="aspectShow">
              <i class="fa fa-paintbrush"></i>
            </button>
            <span class="btn_label">Change widget colour</span>
          </div>
          <div class="icon">
            <button class="widget_btn" @click.prevent="deleteSelf">
              <i class="fa fa-trash"></i>
            </button>
            <span class="btn_label">Delete widget</span>
          </div>
          <div class="icon">
            <button class="widget_btn" @click.prevent="duplicate">
              <i class="fa fa-copy"></i>
            </button>
            <span class="btn_label">Duplicate widget</span>
          </div>
        </div>
      </div>
      <div class="aspects-background" @click="aspectsHide" :ref="'color-picker_' + id">
        <div class="aspects" @click.stop>
          <colour-picker v-model="widget.color" style="width: 300px;" />
        </div>
      </div>
    </div>
    <div class="widget-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ColourPicker from "./inputs/colour-picker.vue";
import EventBus from "./../../bus.js";

export default {
  props: ["id", "name", "icon", "widget"],
  data() {
    return {
      widgetActive:
        this.widget.active === undefined || this.widget.active === true,
      showOnDesktop:
        this.widget.showOnDesktop === undefined ||
        this.widget.showOnDesktop === true,
      showOnMobile:
        this.widget.showOnMobile === undefined ||
        this.widget.showOnMobile === true,
    };
  },
  components: {
    ColourPicker,
  },
  mounted() {
    // next tick
    this.$nextTick(() => {
      // Find the labels and set the value
      let labelDiv = this.$refs["widget-label_" + this.id];
      if (!labelDiv) {
        return;
      }
      // listen to the Enter key, and focus out
      labelDiv.addEventListener("keydown", (event) => {
        if (event.key === "Enter") event.target.blur();
      });
      labelDiv.innerHTML = this.widget.label
        ? this.widget.label
        : "Click to edit label";
    });
  },
  methods: {
    toggleActive() {
      if (this.widget.active === undefined) {
        this.widget.active = false;
      } else {
        this.widget.active = !this.widget.active;
      }
      this.widgetActive = this.widget.active;
      EventBus.$emit('hashchanged');
    },
    toggleMobile() {
      if (this.widget.showOnMobile === undefined) {
        this.widget.showOnMobile = false;
      } else {
        this.widget.showOnMobile = !this.widget.showOnMobile;
      }
      this.showOnMobile = this.widget.showOnMobile;
      EventBus.$emit('hashchanged');
    },
    toggleDesktop() {
      if (this.widget.showOnDesktop === undefined) {
        this.widget.showOnDesktop = false;
      } else {
        this.widget.showOnDesktop = !this.widget.showOnDesktop;
      }
      this.showOnDesktop = this.widget.showOnDesktop;
      EventBus.$emit('hashchanged');
    },
    updateLabel() {
      // We need this workaround to avoid a loop in Vue updating the model and messing with the cursor position
      this.widget.label = this.$refs["widget-label_" + this.id].textContent;
    },
    aspectsHide() {
      // this.$refs["aspect-show-btn_" + this.id].toggle();
      this.$refs["color-picker_" + this.id].classList.remove("active");
      this.$emit("toggleDraggable", true);
    },
    aspectShow() {
      // toggle class on button
      // this.$refs["aspect-show-btn_" + this.id].toggle();

      let picker = this.$refs["color-picker_" + this.id];
      // this widget is visible, but the parent is capturing the drag event
      // so we need to pause the draggable
      if (picker.classList.contains("active")) {
        picker.classList.remove("active");
        this.$emit("toggleDraggable", true);
      } else {
        picker.classList.add("active");
        this.$emit("toggleDraggable", false);
      }
    },
    deleteSelf() {
      if (confirm("Do you really want to delete?")) {
        this.$emit("delete");
      }
    },
    duplicate() {
      if (confirm("duplicate item?")) {
        this.$emit("duplicate");
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.widget {
  height: 100%;

  .header {
    width: 100%;
    z-index: 2;

    background: #2c3e50;
    color: white;

    strong {
      font-family: "Ubuntu Mono", monospace;
      font-weight: bold;
    }

    &:hover {
      .widget_btn {
        opacity: 1;
        pointer-events: unset;
      }
    }
  }
  .widget-label {
    color: #fff5;
  }
  .widget-content {
    z-index: 1;
    overflow: hidden;
    height: 100%;
    padding: 0px 10px;
  }
  .aspects-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100px;
    height: 100%;
    min-height: 100px;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: 0.4s;

    &.active {
      pointer-events: all;
      opacity: 1;
    }

    .aspects {
      background: white;
      border: 1px solid #ccc;
      position: absolute;
      top: 20px;
      right: 140px;
      pointer-events: unset;
      z-index: 2;
      .vc-chrome {
        display: flex;
      }
    }
  }

  .icon {
    width: 36px;
    &:hover {
      .widget_btn {
        color: #797d82;
      }
      .btn_label {
        opacity: 1;
      }
    }
    .btn_label {
      color: #797d82;
      overflow: hidden;
      transition: all 350ms ease;
      position: absolute;
      left: -60px;
      padding: 1px 8px;
      top: 34px;
      white-space: nowrap;
      opacity: 0;
      background: white;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    .widget_btn {
      background: none;
      border: none;
      color: white;
      font-size: 80%;
      opacity: 1;
      pointer-events: none;
      outline: none !important;
      transition: all 350ms ease;
      font-size: 1.3em;
      &.active {
        color: #2c3e50;
      }
      &.red {
        color: #d34e4e;
        fill: #d34e4e;
        svg {
          fill: #d34e4e;
        }
      }
    }
  }
}
</style>